import { PersonenArt } from "../../models/Enums";
import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"
import roundTo5 from "../utils/round_to5";

const calculateHaushaltsgeraeteUnterhaltungselektronik = (erstrisiko: number, personen_art: PersonenArt) => {

    erstrisiko /= 1000

    let current_params = params.haushaltskasko.haushaltsgeraete_unterhaltungselektronik

    let tarif = 0 

    if (personen_art === PersonenArt.Mehrperson) {
        tarif = ValueInInterval(erstrisiko, current_params.tarife_mehrperson, current_params.tarifstufen)
    }
    else {
        tarif = ValueInInterval(erstrisiko, current_params.tarife_einperson, current_params.tarifstufen)
    }

    let praemie = roundTo5(tarif * erstrisiko)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateHaushaltsgeraeteUnterhaltungselektronik