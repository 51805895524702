import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Button, Table, Collapse, DatePicker, Checkbox, Typography, Switch, Space, Card, Slider, Select, Divider, Radio, message, Row, Col, Grid, Layout, Tooltip, Popover, Spin } from "antd";

import { computeTotal3D } from '../calculator/3DGebaeudeCalculator'
import { useForm } from "antd/lib/form/Form";
import { BewohnenArt } from "../models/Enums";
import params from "../data/parameters.json"
import { Content, Header } from "antd/lib/layout/layout";
import transform_amount from "../data/transform_amount_from_params";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import DebounceSelect from "./PlzField"

import initHausrat from "../inital_values/InitHausrat";
import initHaushaltkasko from "../inital_values/InitHaushaltskasko";
import { Contract3DType } from "../models/Contract3DType";
import initContract3D from "../inital_values/InitContract3D";
import calculateSumme from "../calculator/hausrat/SummenerhebungCalculator";
import axios from "axios";
import { ContractRates3DType } from "../models/ContractRates3DType";
import { checkMarktgebiet } from "../data/CheckMarktgebiet";

import type { ColumnsType } from 'antd/es/table';
import { GebaeudeType3D } from "../models/GebaeudeType3D";
import initialGebaeude3D from "../inital_values/InitGebaeude3D";
import GlarnerSachHeader from "./glarnerSachHeader";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { stringify } from "querystring";
import { checkDate } from "../utils/validation_helper";

import { DownOutlined, UpOutlined, MinusOutlined, RightCircleOutlined } from '@ant-design/icons';
import { format } from "path";

interface DataType {
    key: string;
    name: string;
    value: string;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { Panel } = Collapse;

const increments = {
    element_general_1: false,
    element_general_2: false,
    element_general_complete: false,
    element_all_complete: false,
}

const marketgebiet = {
    not: false
}

const ui_config = {
    active_dropdown_key: 1,
    num_person_fields: 1,
    gustavo: false
}

const boxen_style: React.CSSProperties = { background: 'rgb(231 231 231)', padding: '0 0', maxWidth: 180, minWidth: 160, maxHeight: 110 };
const table_style: React.CSSProperties = { background: 'rgb(231 231 231)', padding: '0 0' }; //, maxWidth: 180, minWidth: 160 

const { Option } = Select;

function get_options(options_from_params: any[], transform: boolean) {

    let options_to_select: React.ReactNode[] = [];

    for (let i = 0; i < options_from_params.length; i++) {
        if (typeof options_from_params[i] === "string") {
            options_to_select.push(<Option value={options_from_params[i]} key={options_from_params[i]}>{options_from_params[i]}</Option>);
        }
        else {
            if (transform) {
                options_to_select.push(<Option value={transform_amount(options_from_params[i])} key={transform_amount(options_from_params[i])}>{transform_amount(options_from_params[i]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Option>);
            }
            else {
                options_to_select.push(<Option value={(options_from_params[i])} key={(options_from_params[i])}>{options_from_params[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Option>);
            }
        }
    }

    return options_to_select
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

/* eslint-enable no-template-curly-in-string */

const Gebaeudeversicherung = () => {
    const [form] = useForm<Contract3DType>()
    const navigate = useNavigate()
    const [total, setTotal] = React.useState<ContractRates3DType>({
        TotalPraemien: 0,
        TotalRabatt: 0,
        TotalOhneStempel: 0,
        TotalOhneStempelMonat: 0,
        Stempel: 0,
        TotalMitStempel: 0
    } as ContractRates3DType)
    const [isSendInProgress, setIsSendInProgress] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/gebaeude/gebaeude3d" });
    })

    const initValues = initContract3D

    const [state, setState] = useState<Contract3DType>(initValues)

    const onValuesChange = (_: any) => {

        console.log("Current Values:", form.getFieldsValue())
        console.log("UI params:", ui_config)
        let current_total = computeTotal3D(form.getFieldsValue().GebaeudeV as GebaeudeType3D)
        console.log("Total:", current_total)
        setTotal(current_total)
        setState(form.getFieldsValue())
    }

    const sendPdf = (obj: any) => {
        setIsSendInProgress(true)

        axios.post("https://praemienrechner-backend-webapp.azurewebsites.net/gebaeude", obj)
            .then(response => {
                if (response.status !== 200) {
                    message.error('Es ist leider ein unerwarteter Verarbeitungsfehler aufgetreten.')
                    setIsSendInProgress(false)
                }
                else {
                    navigate('/success')
                }
            }).catch(() => {
                message.error('Es ist leider ein unerwarteter Verarbeitungsfehler aufgetreten.')
                setIsSendInProgress(false)
            })
    }

    const onChange1 = (selectedValue: string) => {
        ReactGA.send({ hitType: "pageview", page: "/gebaeude/step1" });

        let plz = parseInt(selectedValue)
        let marktgebiet_info = checkMarktgebiet(plz)

        console.log("Plz:", plz, "Marketgebiet:", marktgebiet_info)

        if (marktgebiet_info.Found === false) {

            marketgebiet.not = true
        }
        else {
            marketgebiet.not = false
            increments.element_general_1 = true

            if (marktgebiet_info.IsGustavo === false) {
                form.setFieldsValue({ GebaeudeV: { FeuerElementarEnable: false } })
                ui_config.gustavo = false
            }
            else {
                ui_config.gustavo = true
                form.setFieldsValue({ GebaeudeV: { FeuerElementarEnable: true } })
            }
        }

        let current_total = computeTotal3D(form.getFieldsValue().GebaeudeV as GebaeudeType3D)
        console.log("Total:", current_total)
        setTotal(current_total)
        setState(form.getFieldsValue())
    };

    const onChange2 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/gebaeude/step2" });
        increments.element_general_2 = true
    };

    const onChange3 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/gebaeude/step3" });
        window.scrollTo(0, 0);

        ui_config.num_person_fields = checked
        increments.element_general_complete = true
    };

    const onChangeCollapse = (key: any) => {
        if (increments.element_general_complete === true) {
            ui_config.active_dropdown_key = key
        }

        setState(form.getFieldsValue())
    };

    const onOfferte = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/gebaeude/onOfferte" });

        increments.element_all_complete = true
        ui_config.active_dropdown_key = 5
        setState(form.getFieldsValue())
    };

    let glasbruchVSummen = () => {
        let sum = params.gebaeude.glasbruch.versicherungssummen
        sum[2] = form.getFieldsValue().GebaeudeV.Vollwert / 1000
        return sum
    };

    const onZusammenstellung = ()=> {
        window.scrollTo(0, 0);
        ui_config.active_dropdown_key = 4
    }


    const { useBreakpoint } = Grid
    const screens = useBreakpoint()
    const isMobile = screens.xs

    return (
        <Layout>
            <Form
                {...layout}
                form={form}
                initialValues={initContract3D}
                name="nest-messages"
                colon={false}
                validateMessages={validateMessages}
                onValuesChange={onValuesChange}
                onFinish={(values) => {
                    console.log("onFinish:", values)
                    sendPdf(values)
                }}
            >
                {/* <Sider width={800} style={{ background: "white" }}>
                {state && <ReactJson src={{ "root": state }} theme="monokai" />}
            </Sider> */}
                <Layout>
                    <Header style={{ position: 'fixed', zIndex: 1000, width: '100%', padding: '0px 0px 0px 0px' }} className="header-background">
                        <GlarnerSachHeader title1="Prämienrechner" title2="Gebäudeversicherung DREIDIMENSIONAL" />
                        {(!isMobile) &&
                            <>
                                <Row justify="center" className="title" gutter={{ xs: 2, sm: 16, md: 24, lg: 32 }}>
                                    <div className="title-non-mobile"><h1>Prämienrechner <br /> Gebäudeversicherung DREIDIMENSIONAL </h1></div>
                                </Row>
                            </>
                        }


                        <Row className="praemie-display">
                            <div className="praemie-display-text">
                                {(total.TotalOhneStempelMonat > 0 && !marketgebiet.not) &&
                                    <h2 style={{ color: 'rgb(255, 255, 255)' }}>Ihre Prämie: <span className="hide-on-mobile">CHF {total.TotalOhneStempel.toFixed(2)} / Jahr  </span><span style={{ fontSize: 11 }}> - (ca. CHF {total.TotalOhneStempelMonat.toFixed(2)} / Monat)  </span> <Popover content={<>exkl. 5% eidg. Stempelabgabe</>}><InfoCircleOutlined className="pop-info" /></Popover></h2>
                                }
                                {(total.TotalOhneStempelMonat <= 0 || marketgebiet.not) &&
                                    <h2 style={{ color: 'rgb(255, 255, 255)' }}>Ihre Prämie: <span className="hide-on-mobile">CHF xxx / Jahr </span><span style={{ fontSize: 13 }}> - (ca. CHF xx / Monat)  </span> <Popover content={<>exkl. 5% eidg. Stempelabgabe</>}><InfoCircleOutlined className="pop-info" /></Popover></h2>
                                }

                            </div>
                        </Row>
                    </Header>

                    <Content className="insurance-content">
                        <Row justify="center">
                            <Col sm={{ span: 22 }} md={{ span: 18 }} lg={{ span: 14 }} style={{ maxWidth: '980px' }}>
                                <Collapse defaultActiveKey={['1']} ghost accordion onChange={onChangeCollapse} activeKey={ui_config.active_dropdown_key}>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 1 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 1 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" style={{ height: '50px' }} onClick={e => { ui_config.active_dropdown_key = 1 }}><h2>Allgemeine Angaben</h2></Button>
                                            </Col>
                                        </>
                                    } key="1" showArrow={false}>
                                        <Row justify="center" align="middle">
                                            <h3>Wohnort: <Popover title={"Wohnort"} content={<>Gerne prüfen wir, ob Ihr Objekt in unserem Marktgebiet liegt.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                        </Row>

                                        <Row justify="center" align="middle">
                                            <Col span={16}>
                                                <Form.Item name={["Vertragsnehmer", "Plz"]}>
                                                    <DebounceSelect
                                                        showSearch
                                                        value={{ label: "", value: "", key: "" }}
                                                        placeholder="Geben Sie eine PLZ ein"
                                                        onChange={onChange1}
                                                        // onClick={e => window.scrollTo(0, 0)}
                                                        // fetchOptions={(search: string): Promise<any> => { return fetch("test") }}
                                                        style={{ maxWidth: '300px' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {(marketgebiet.not === true) &&
                                            <Row justify="center">
                                                <Col xs={22} sm={12}  >
                                                    <div className="marktgebietWarning">
                                                        Ihr Wohnort befindet sich ausserhalb unseres Marktgebiets. <br /> Ein Vertragsabschluss ist nur unter bestimmten Voraussetzungen möglich. <br /> Bitte nehmen Sie direkt mit uns Kontakt auf
                                                    </div>
                                                </Col>
                                            </Row>
                                        }


                                        {(increments.element_general_1 === true && !marketgebiet.not) &&
                                            <>
                                                <Row justify="center">
                                                    <h3>Gebäudeversicherungssumme: <Popover title={"Gebäudeversicherungssumme"} content={<>Die Versicherungssumme entspricht dem Betrag, welcher für den Wiederaufbau des versicherten Gebäudes erforderlich ist.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                </Row>

                                                <Row justify="center">
                                                    {(form.getFieldsValue().GebaeudeV.Vollwert > transform_amount(params.gebaeude.general_params.max_versicherungssumme)) &&
                                                        (<>
                                                            <p style={{ color: "red" }}>Der eingegebene Wert übersteigt den Maximalbetrag. Gerne nehmen wir persönlich mit Ihnen Kontakt auf.</p>
                                                        </>)}
                                                </Row>
                                                <Row justify="center">
                                                    <Form.Item name={["GebaeudeV", "Vollwert"]}>
                                                        <InputNumber
                                                            controls={false}
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}
                                                            // parser={value => value!.replace(/\$\s?|(\'*)/g, '')}
                                                            onChange={onChange2} min={0} max={20000000} style={{ width: 200 }} />
                                                    </Form.Item>
                                                </Row>
                                            </>}

                                        {(increments.element_general_2 === true && !marketgebiet.not) &&
                                            <>
                                                <Row justify="center">
                                                    <h3>Wohngebäude dauernd bewohnt? <Popover content={<>Dauernd bewohnt <br /> Darunter fallen Einfamilienhäuser oder Mehrfamilienhäuser, die ständig bewohnt werden. <br /> <br /> Nicht dauernd bewohnt <br /> Darunter verstehen sich Häuser (z.B. Ferienhhaus), welche wiederkehrend über einen längeren Zeitraum pro Jahr unbewohnt sind. </>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                </Row>


                                                <Row justify="center" align="middle">
                                                    <Col span={24}>
                                                        <Form.Item name={["GebaeudeV", "BewohnenArt"]}>
                                                            <Radio.Group buttonStyle="solid" onChange={onChange3}>
                                                                <Radio.Button className="button-bewohnt" value={BewohnenArt.Dauernd}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}><span className="title-bewohnt" >Dauernd bewohnt</span></Space></Radio.Button>
                                                                <Radio.Button className="button-bewohnt" value={BewohnenArt.NichtDauernd}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}><span className="title-bewohnt" >Nicht Dauernd bewohnt</span></Space></Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(increments.element_general_complete === true && !marketgebiet.not) &&
                                            <>
                                                <div style={table_style} className="header-boxen">

                                                    <Row justify="center">
                                                        <h3><Popover title={"Gebäudeversicherung"} content={<>Die Gebäudeversicherung versichert Ihr Haus bei Schäden durch Feuer, Wasser, Diebstahl, Glasbruch,  Erdbeben usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Gebäudeversicherung:</h3>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                {(ui_config.gustavo === true) &&
                                                                    <span><h4> <Popover title={"Feuer und Elementar"} content={<><b>Feuer</b> <br />Brand, Rauch, Blitzschlag, Explosion usw.<br /><br /> <b>Elementar</b> <br />Hochwasser, Überschwemmung, Sturm über 75 km/h, Hagel, Lawine, Erdrutsch usw. <br />Bitte beachten Sie, dass Erdbeben in diesem Sinn kein Elementar-Ereignis ist und separat versichert werden kann.</>}><InfoCircleOutlined className="pop-info" /></Popover> Feuer und Elementar </h4></span>
                                                                }

                                                                {(ui_config.gustavo === false) &&
                                                                    <span><h4> <Popover title={"Feuer und Elementar"} content={<>Feuer- und Elementarschäden sind bei der Kantonalen Gebäudeversicherung versichert.</>}><InfoCircleOutlined className="pop-info" /></Popover> Feuer und Elementar </h4></span>
                                                                }


                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={11} xs={13}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                {(ui_config.gustavo === true) &&
                                                                    <Form.Item name={["GebaeudeV", "FeuerEnable"]} valuePropName="checked">
                                                                        <Switch defaultChecked disabled />
                                                                    </Form.Item>
                                                                }

                                                                {(ui_config.gustavo === false) &&
                                                                    <Form.Item name={["GebaeudeV", "FeuerEnable"]} valuePropName="checked">
                                                                        <Switch defaultChecked={false} disabled />
                                                                    </Form.Item>
                                                                }

                                                            </Space>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Wasser"} content={<>Wasser aus Leitungen, eindringendes Regen-, Schnee- oder Grundwasser usw. <br /> <br />Freilegungskosten sind mitversichert. Dies umfasst die Kosten für das Freilegen sowie Zumauern undichter Leitungen,  für Leckortungsmassnahmen und für die Reparatur der undichten Leitungsstelle.</>}><InfoCircleOutlined className="pop-info" /></Popover> Wasser </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={11} xs={13}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Switch disabled={true} defaultChecked />
                                                            </Space>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right', lineHeight: '15px' }}>
                                                                <span><h4> <Popover title={"Umgebungsschäden Feuer und Elementar"} content={<>Aufwendungen für Schäden ausserhalb des Gebäudes, jedoch innerhalb des Grundstücks wie beispielsweise Stützmauern, Zäune, Treppen, Planien, Beleuchtungsanlagen, Rasenflächen, Ziersträucher, Bäume usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Umgebungsschäden <br />Feuer und Elementar </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={3} xs={4}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "UmgebungsschaedenFeuerElementarEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                        <Col sm={8} xs={9}>
                                                            <Form.Item name={["GebaeudeV", "UmgebungsschaedenFeuerElementarErstrisiko"]} valuePropName="checked">
                                                                <Select style={{ width: 100, paddingTop: 5 }} disabled={!state.GebaeudeV.UmgebungsschaedenFeuerElementarEnable} defaultValue={initValues.GebaeudeV.UmgebungsschaedenFeuerElementarErstrisiko}>
                                                                    {get_options(params.gebaeude.umgebung_feuer_elementar.versicherungssummen, true)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Glasbruch"} content={<>Bruch von Fenstern, Lavabos, Glaskeramik- und Induktionskochfelder usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Glasbruch </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={3} xs={4}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "GlasbruchEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                        <Col sm={8} xs={9}>
                                                            <Form.Item name={["GebaeudeV", "GlasbruchErstrisiko"]} valuePropName="checked">
                                                                <Select style={{ width: 100, paddingTop: 5 }} disabled={!state.GebaeudeV.GlasbruchEnable} defaultValue={initValues.GebaeudeV.GlasbruchErstrisiko}>
                                                                    {get_options(glasbruchVSummen(), true)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Gebäudetechnik"} content={<>Quasi eine Kasko-Deckung für gebäudetechnische Anlagen wie elektrische Sonnenstoren, Fotovoltaikanlagen, Boiler, Wärmepumpen, Waschmaschine, Tumbler, Lift, Whirlpool, Sauna usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Gebäudetechnik </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={3} xs={4}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "GebaeudetechnikEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                        <Col sm={8} xs={9}>
                                                            <Form.Item name={["GebaeudeV", "GebaeudetechnikErstrisiko"]} valuePropName="checked">
                                                                <Select style={{ width: 100, paddingTop: 5 }} disabled={!state.GebaeudeV.GebaeudetechnikEnable} defaultValue={initValues.GebaeudeV.GebaeudetechnikErstrisiko}>
                                                                    {get_options(params.gebaeude.gebaeudetechnik.versicherungssummen, true)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Diebstahl"} content={<>Beschädigungen am Gebäude durch Einbruchdiebstahl oder Beraubung. <br /> <br />Schlossänderungskosten sind mitversichert. Darunter fallen Kosten für Reparatur, Anpassungen oder Ersatz von Schliesssystemen und Schlossänderungen.</>}><InfoCircleOutlined className="pop-info" /></Popover> Diebstahl </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={11} xs={13}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "DiebstahlEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Erdbeben"} content={<>Erschütterungen am Gebäude</>}><InfoCircleOutlined className="pop-info" /></Popover> Erdbeben </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={11} xs={13}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "ErdbebenEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col sm={12} xs={10}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                <span><h4> <Popover title={"Extended Coverage"} content={<>Böswillige Beschädigung, Innere Unruhen, Ungeziefer (Marder, Mäuse, Ratten, Bienen und Wespen), Fahrzeuganprall, Gebäudeeinsturz usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Extended Coverage </h4></span>
                                                            </Space>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col sm={11} xs={13}>
                                                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                <Form.Item name={["GebaeudeV", "ExtendedCoverageEnable"]} valuePropName="checked">
                                                                    <Switch />
                                                                </Form.Item>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>}
                                    </Panel>

                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 4 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 4 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" style={{ height: '50px' }} onClick={onZusammenstellung} disabled={!increments.element_general_complete || marketgebiet.not}><h2>Zusammenstellung</h2></Button>
                                            </Col>
                                        </>
                                    }
                                        key="4" showArrow={false}>
                                        {(total.TotalRabatt > 0) && <>
                                            <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px' }}>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'left' }}>
                                                        Total Prämien
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span>{total.TotalPraemien.toFixed(2)}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'left' }}>
                                                        Rabatt
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span>{total.TotalRabatt.toFixed(2)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        }

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Nettoprämie
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.TotalOhneStempel.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Eidg. Stempelabgabe
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.Stempel.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <b>Total Jahresprämien</b>
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <b><span>{total.TotalMitStempel.toFixed(2)}</span></b>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row justify="center" style={{ paddingTop: 10 }}>
                                            <Button onClick={onOfferte} style={{ filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))', height: '50px', width: '250px' }} type="primary">OFFERTE ANFORDERN</Button>
                                        </Row>
                                    </Panel>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 5 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 5 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" style={{ height: '50px' }} onClick={e => { ui_config.active_dropdown_key = 5 }} disabled={!increments.element_general_complete || marketgebiet.not}><h2>Kontaktangaben</h2></Button>
                                            </Col>
                                        </>
                                    }
                                        key="5" showArrow={false}>
                                        <Row justify="center">
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Anrede"]}
                                                    rules={[{ required: true, message: 'Bite wählen Sie Ihre Anrede aus.' }]}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Select placeholder="Anrede" style={{ width: '100%' }}>
                                                        <Select.Option key="Frau">Frau</Select.Option>
                                                        <Select.Option key="Herr">Herr</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Vorname"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihren Vornahmen an.' }]}
                                                >
                                                    <Input placeholder="Vorname" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Nachname"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihren Namen an.' }]}
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Geburtsdatum"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihr Geburtsdatum an: TT.MM.JJJJ', validator: checkDate }]}
                                                >
                                                    <Input placeholder="Geburtsdatum" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Strasse"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihre Adresse an.' }]}
                                                >
                                                    <Input placeholder="Adresse" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Plz"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihre PLZ an.' }]}>
                                                    <DebounceSelect
                                                        // mode="multiple"
                                                        showSearch
                                                        value={{ label: "", value: "", key: "" }}
                                                        placeholder="PLZ Ort"
                                                        onChange={onChange1}
                                                        //onChange={() => { increments.plz = true }}
                                                        // fetchOptions={(search: string): Promise<any> => { return fetch("test") }}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Firma"]}
                                                >
                                                    <Input placeholder="Firma" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Email"]}
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'E-Mail',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Bitte geben Sie Ihre E-Mail an',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="E-Mail" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "NetzNr"]}
                                                    rules={[
                                                        {
                                                            message: 'Telefonnummer',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Bitte geben Sie Ihre Telefonnummer an',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Telefonnummer"
                                                        style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row justify="center" style={{ paddingTop: 15 }}>
                                            {isSendInProgress ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
                                                <Button htmlType="submit" style={{ filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))', height: '50px', width: '250px' }} type="primary">OFFERTE ANFORDERN</Button>}
                                        </Row>
                                        {(isSendInProgress) &&
                                            <Row justify="center">
                                                <Col xs={22} sm={16}>
                                                    <div>
                                                        Ihre Daten werden verarbeitet. Bitte haben Sie einen Augenblick Geduld.
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Form.Item name={["GebaeudeV", "BewohnenArt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "UmgebungsschaedenFeuerElementarEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "UmgebungsschaedenFeuerElementarErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "FeuerElementarEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "DiebstahlEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "ErdbebenEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "GlasbruchEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "GlasbruchErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "ExtendedCoverageEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "GebaeudetechnikEnable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["GebaeudeV", "GebaeudetechnikErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["DatumHeute"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["OffertenNr"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["VertragsBeginn"]} style={{ display: 'none' }}></Form.Item>
                    </Content>
                </Layout>
            </Form >
        </Layout >
    );
};

export default Gebaeudeversicherung;
