import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";

const calculateErdbeben = (vollwert: number) => {

    vollwert /= 1000

    let params_erdbeben = params.hausratsversicherung.erdbeben
    let praemie = roundTo5(params_erdbeben.tarif * vollwert)

    return { "praemie": praemie, "rabatt": 0 }
}

export default calculateErdbeben