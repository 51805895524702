import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Table, Collapse, DatePicker, Checkbox, Typography, Switch, Space, Card, Slider, Select, Divider, Radio, message, Row, Col, Grid, Layout, Tooltip, Popover, Spin } from "antd";

import { computeTotal } from '../calculator/HaushaltsversicherungCalculator'
import { useForm } from "antd/lib/form/Form";
import HausratsType from "../models/Hausrat";
import { EinrichtungsStandard, GebaeudeTyp, Besitzverhaeltnis, PersonenArt } from "../models/Enums";
import params from "../data/parameters.json"
import { HaushaltsType7S } from "../models/HaushaltsType";
import { Content, Header } from "antd/lib/layout/layout";
import RechtsschutzType from "../models/Rechtsschutz";
import transform_amount from "../data/transform_amount_from_params";
import { ConsoleSqlOutlined, InfoCircleOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import DebounceSelect from "./PlzField"

import initHausrat from "../inital_values/InitHausrat";
import initHaushaltkasko from "../inital_values/InitHaushaltskasko";
import { Contract7SType } from "../models/Contract7SType";
import initContract7S from "../inital_values/InitContract7S";
import locale from 'antd/es/date-picker/locale/de_DE';
import calculateSumme from "../calculator/hausrat/SummenerhebungCalculator";
import { Dictionary, fromPairs } from "lodash";
import axios from "axios";
import { ContractRates7SType } from "../models/ContractRates7SType";
import { str } from "ajv";
import { checkMarktgebiet } from "../data/CheckMarktgebiet";
import ReactGA from "react-ga4";
import GlarnerSachHeader from "./glarnerSachHeader";

import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from "react-router-dom";
import { checkDate } from "../utils/validation_helper";
import { formatMoney } from "../utils/formatterHelper";
import { useRef } from 'react';

import { DownOutlined, UpOutlined, MinusOutlined, RightCircleOutlined } from '@ant-design/icons';

interface DataType {
    key: string;
    name: string;
    value: string;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { Panel } = Collapse;

const increments = {
    element_general_1: false,
    element_general_2: false,
    element_general_complete: false,
    element_hausrat_1: false,
    element_hausrat_2: false,
    element_hausrat_3: false,
    element_hausrat_4: false,
    element_vs_complete: false,
    element_privathaft_1: false,
    element_privathaft_complete: false,
    element_all_complete: false,
}

const insurance = {
    not: false
}

const ui_config = {
    active_dropdown_key: 1,
    num_person_fields: 1
}

const boxen_style: React.CSSProperties = { background: 'rgb(231 231 231)', padding: '0 0', maxWidth: 180, minWidth: 160, maxHeight: 110 };
const table_style: React.CSSProperties = { background: 'rgb(231 231 231)', padding: '0 0' }; //, maxWidth: 180, minWidth: 160 

const { Option } = Select;

function get_options(options_from_params: any[], transform: boolean) {

    let options_to_select: React.ReactNode[] = [];

    for (let i = 0; i < options_from_params.length; i++) {
        if (typeof options_from_params[i] === "string") {
            options_to_select.push(<Option value={options_from_params[i]} key={options_from_params[i]}>{options_from_params[i]}</Option>);
        }
        else {
            if (transform) {
                options_to_select.push(<Option value={transform_amount(options_from_params[i])} key={transform_amount(options_from_params[i])}>{transform_amount(options_from_params[i]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Option>);
            }
            else {
                options_to_select.push(<Option value={(options_from_params[i])} key={(options_from_params[i])}>{options_from_params[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Option>);
            }
        }
    }

    return options_to_select
}

function getRoomsToSelect(numPersons: number, options_array: any[]) {

    let new_options_array = []

    if (numPersons <= 7 && numPersons > 0) {
        for (let i = 0; i < params['summenerhebung'].zimmer_array.length; i++) {

            let key_zimmer = (params['summenerhebung'].zimmer_array[i] >= 7) ? "7 plus" : params['summenerhebung'].zimmer_array[i].toString()
            key_zimmer = key_zimmer + " room"

            let curren_params = params['summenerhebung']
            let rates_zimmer = curren_params.zimmer_personen[key_zimmer as keyof typeof curren_params.zimmer_personen]
            let person_key = numPersons.toString() + " person"

            let person_rate = rates_zimmer[person_key as keyof typeof rates_zimmer]

            if (person_rate > 0) {
                new_options_array.push(options_array[i])
            }
        }
    }

    return new_options_array
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

/* eslint-enable no-template-curly-in-string */

const Hausratsversicherung = () => {
    const [form] = useForm<Contract7SType>()
    const navigate = useNavigate()
    const [total, setTotal] = React.useState<ContractRates7SType>({
        Hausrat: 0,
        HaushaltsKasko: 0,
        Privathaft: 0,
        Rechtsschutz: 0,
        TotalPraemien: 0,
        TotalRabatt: 0,
        TotalOhneStempel: 0,
        TotalOhneStempelMonat: 0,
        Stempel: 0,
        TotalMitStempel: 0
    } as ContractRates7SType)

    const [isSendInProgress, setIsSendInProgress] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/hausratsversicherung" });
    })

    const initValues = initContract7S;

    const [state, setState] = useState<Contract7SType>(initValues)

    const onValuesChange = (_: any) => {
        if (form.getFieldsValue().HaushaltsV.Hausrat.Enable === true) {
            form.setFieldsValue({ HaushaltsV: { Haushaltskasko: { Enable: true } } })
        }
        else {
            form.setFieldsValue({ HaushaltsV: { Haushaltskasko: { Enable: false } } })
        }

        // Glasbruch/Mobiliarglas can't be enabled at the same time
        if (form.getFieldsValue().HaushaltsV.Hausrat.Glas === true && state.HaushaltsV.Hausrat.Glas === false) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Mobiliar: false } } })
        }
        if (form.getFieldsValue().HaushaltsV.Hausrat.Mobiliar === true && state.HaushaltsV.Hausrat.Mobiliar === false) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Glas: false } } })
        }

        if (form.getFieldsValue().HaushaltsV.Hausrat.Summenerhebung != null) {
            if (form.getFieldsValue().HaushaltsV.Hausrat.Summenerhebung.Personen != null) {
                if (form.getFieldsValue().HaushaltsV.Hausrat.Summenerhebung.Personen !== 1) {
                    form.setFieldsValue({ HaushaltsV: { Hausrat: { PersonenArt: PersonenArt.Mehrperson } } })
                    form.setFieldsValue({ HaushaltsV: { Haushaltskasko: { PersonenArt: PersonenArt.Mehrperson } } })
                    form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { PersonenArt: PersonenArt.Mehrperson } } })
                    form.setFieldsValue({ HaushaltsV: { Rechtsschutz: { PersonenArt: PersonenArt.Mehrperson } } })
                }
                else {
                    form.setFieldsValue({ HaushaltsV: { Hausrat: { PersonenArt: PersonenArt.Einperson } } })
                    form.setFieldsValue({ HaushaltsV: { Haushaltskasko: { PersonenArt: PersonenArt.Einperson } } })
                    form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { PersonenArt: PersonenArt.Einperson } } })
                    form.setFieldsValue({ HaushaltsV: { Rechtsschutz: { PersonenArt: PersonenArt.Einperson } } })
                }
            }

            // Calculate Summenerhebung, Check if custom value was entered
            let value = calculateSumme(form.getFieldsValue().HaushaltsV.Hausrat.Summenerhebung)
            if (form.getFieldsValue().HaushaltsV.Hausrat.VSumme === 0 ||
                value !== calculateSumme(state.HaushaltsV.Hausrat.Summenerhebung)) {
                form.setFieldsValue({ HaushaltsV: { Hausrat: { VSumme: value } } })
            }
        }

        if (form.getFieldsValue().HaushaltsV.Hausrat.VSumme === state.HaushaltsV.Hausrat.VSumme) {
            let newVSumme = Math.round(form.getFieldsValue().HaushaltsV.Hausrat.VSumme / 1000) * 1000;

            form.setFieldsValue({ HaushaltsV: { Hausrat: { VSumme: newVSumme } } })
        }

        console.log("Current Values:", form.getFieldsValue())
        console.log("UI params:", ui_config)
        let current_total = computeTotal(form.getFieldsValue().HaushaltsV as HaushaltsType7S)
        console.log("Total:", current_total)
        setTotal(current_total)
        setState(form.getFieldsValue())
    }

    const onAllgemein = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_vs_complete) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
        }
        if (!increments.element_privathaft_complete) {
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
        }

        onValuesChange(1)

        if (!state.HaushaltsV.Hausrat.Enable &&
            !state.HaushaltsV.Privathaftpflicht.Enable &&
            !state.HaushaltsV.Rechtsschutz.Enable) {
            message.warning({
                content: 'Bitte wählen Sie eine Versicherung',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });

            insurance.not = true;
        }

        if (ui_config.active_dropdown_key === 1) {
            ui_config.active_dropdown_key = 10
        }
        else {
            ui_config.active_dropdown_key = 1
        }



        onValuesChange(1)
    };

    const onChangeHausrat = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_privathaft_complete) {
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
        }

        onValuesChange(1)

        if (checked === true || checked === false) {
            if (checked === false &&
                !state.HaushaltsV.Privathaftpflicht.Enable &&
                !state.HaushaltsV.Rechtsschutz.Enable) {
                ui_config.active_dropdown_key = 1
                message.warning({
                    content: 'Bitte wählen Sie eine Versicherung',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });

                insurance.not = true;
                ui_config.active_dropdown_key = 1
            }
            else if (checked === false) {
                insurance.not = false;
                ui_config.active_dropdown_key = 10
            }
            else if (checked === true) {
                                insurance.not = false;
                ui_config.active_dropdown_key = 2
            }
        }
        else if (!increments.element_vs_complete) {
            if (form.getFieldsValue().HaushaltsV.Hausrat.Enable) {
                form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
                if (!state.HaushaltsV.Privathaftpflicht.Enable &&
                    !state.HaushaltsV.Rechtsschutz.Enable) {
                    ui_config.active_dropdown_key = 1
                    message.warning({
                        content: 'Bitte wählen Sie eine Versicherung',
                        className: 'custom-class',
                        style: {
                            marginTop: '20vh',
                        },
                    });
                    insurance.not = true;
                }
                else {
                    insurance.not = false;
                    ui_config.active_dropdown_key = 10
                }

            }
            else {
                form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: true } } })
                insurance.not = false;
                ui_config.active_dropdown_key = 2
            }
        }
        else if (ui_config.active_dropdown_key === 2) {
            ui_config.active_dropdown_key = 10
        }
        else {
            ui_config.active_dropdown_key = 2
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: true } } })
        }

        onValuesChange(1)
    };

    const onChangePrivathaft = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_vs_complete) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
        }

        onValuesChange(1)

        if (checked === true || checked === false) {
            if (checked === false &&
                !state.HaushaltsV.Hausrat.Enable &&
                !state.HaushaltsV.Rechtsschutz.Enable) {
                ui_config.active_dropdown_key = 1
                message.warning({
                    content: 'Bitte wählen Sie eine Versicherung',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });

                insurance.not = true;
                ui_config.active_dropdown_key = 1
            }
            else if (checked === false) {
                insurance.not = false;
                ui_config.active_dropdown_key = 10
            }
            else if (checked === true) {
                insurance.not = false;
                ui_config.active_dropdown_key = 3
            }
        }
        else if (!increments.element_privathaft_complete) {
            if (form.getFieldsValue().HaushaltsV.Privathaftpflicht.Enable) {
                form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
                if (!state.HaushaltsV.Hausrat.Enable &&
                    !state.HaushaltsV.Rechtsschutz.Enable) {
                    ui_config.active_dropdown_key = 1
                    message.warning({
                        content: 'Bitte wählen Sie eine Versicherung',
                        className: 'custom-class',
                        style: {
                            marginTop: '20vh',
                        },
                    });
                    insurance.not = true;
                    ui_config.active_dropdown_key = 1
                }
                else {
                    insurance.not = false;
                    ui_config.active_dropdown_key = 3
                }

            }
            else {
                form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: true } } })
                insurance.not = false;
                ui_config.active_dropdown_key = 3
            }
        }
        else if (ui_config.active_dropdown_key === 3) {
            ui_config.active_dropdown_key = 10
        }
        else {
            ui_config.active_dropdown_key = 3
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: true } } })
        }

        onValuesChange(1)
    };

    const onChangeRechtsschutz = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_vs_complete) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
        }

        if (!increments.element_privathaft_complete) {
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
        }

        onValuesChange(1)

        if (checked === true || checked === false) {
            if (checked === false &&
                !state.HaushaltsV.Hausrat.Enable &&
                !state.HaushaltsV.Privathaftpflicht.Enable) {
                ui_config.active_dropdown_key = 1
                message.warning({
                    content: 'Bitte wählen Sie eine Versicherung',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });

                insurance.not = true;
            }
            else {
                insurance.not = false;
                ui_config.active_dropdown_key = 10
            }
        }
        else {
            if ((form.getFieldsValue().HaushaltsV.Rechtsschutz.Enable === true) &&
                !state.HaushaltsV.Hausrat.Enable &&
                !state.HaushaltsV.Privathaftpflicht.Enable) {
                ui_config.active_dropdown_key = 1
                form.setFieldsValue({ HaushaltsV: { Rechtsschutz: { Enable: false } } })
                message.warning({
                    content: 'Bitte wählen Sie eine Versicherung',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
                insurance.not = true;
            }
            else if (form.getFieldsValue().HaushaltsV.Rechtsschutz.Enable === true) {
                form.setFieldsValue({ HaushaltsV: { Rechtsschutz: { Enable: false } } })
                insurance.not = false;
                ui_config.active_dropdown_key = 10
            }
            else {
                form.setFieldsValue({ HaushaltsV: { Rechtsschutz: { Enable: true } } })
                insurance.not = false;
                ui_config.active_dropdown_key = 10
            }
        }

        onValuesChange(1)
    };

    const onZusammenstellung = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_vs_complete) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
        }
        if (!increments.element_privathaft_complete) {
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
        }

        onValuesChange(1)

        if ((!state.HaushaltsV.Hausrat.Enable &&
            !state.HaushaltsV.Privathaftpflicht.Enable &&
            !state.HaushaltsV.Rechtsschutz.Enable) ||
            total.TotalPraemien <= 0) {
            ui_config.active_dropdown_key = 1
            insurance.not = true;
            message.warning({
                content: 'Bitte wählen Sie eine Versicherung',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }
        else {
            insurance.not = false;
            ui_config.active_dropdown_key = 4
        }
    };

    const onKontakt = (checked: any) => {
        window.scrollTo(0, 0);

        if (!increments.element_vs_complete) {
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Enable: false } } })
        }
        if (!increments.element_privathaft_complete) {
            form.setFieldsValue({ HaushaltsV: { Privathaftpflicht: { Enable: false } } })
        }

        onValuesChange(1)

        if ((!state.HaushaltsV.Hausrat.Enable &&
            !state.HaushaltsV.Privathaftpflicht.Enable &&
            !state.HaushaltsV.Rechtsschutz.Enable) ||
            total.TotalPraemien <= 0) {
            ui_config.active_dropdown_key = 1
            insurance.not = true;
            message.warning({
                content: 'Bitte wählen Sie eine Versicherung',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }
        else {
            insurance.not = false;
            ui_config.active_dropdown_key = 5
        }
    };

    const sendPdf = (obj: any) => {
        setIsSendInProgress(true)

        axios.post("https://praemienrechner-backend-webapp.azurewebsites.net/hausrat", obj)
            .then(response => {
                if (response.status !== 200) {
                    message.error('Es ist leider ein unerwarteter Verarbeitungsfehler aufgetreten.')
                    setIsSendInProgress(false)
                }
                else {
                    navigate('/success')
                }
            })
    }

    const onChange1 = (selectedValue: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step1" });
        console.log("change1");

        increments.element_general_1 = true
        let plz = parseInt(selectedValue)
        let marktgebiet_info = checkMarktgebiet(plz)

        console.log("Plz:", plz, "Marketgebiet:", marktgebiet_info)

        if (marktgebiet_info.Found === false) {
            message.error({
                content: 'Ihr Wohnort befindet sich ausserhalb unseres Marktgebiets. Ein Vertragsabschluss ist nur unter bestimmten Voraussetzungen möglich und wird nach dem Einreichen überprüft. Sie können den Offert-Prozess unverbindlich fortsetzen.',
                style: {
                    marginTop: '20vh'
                }
            });
        }
    };

    const onChange2 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step2" });
        console.log("change2");
        increments.element_general_2 = true
    };

    const onChangeGeneralDone = (checked: any) => {
        window.scrollTo(0, 0);

        ui_config.num_person_fields = checked
        increments.element_general_complete = true

        if (checked > 1) {
            increments.element_hausrat_1 = true
        }
        else {
            increments.element_hausrat_2 = true
            form.setFieldsValue({ HaushaltsV: { Hausrat: { Summenerhebung: { NumKinder: 0 } } } })
        }
        increments.element_privathaft_1 = true



        if (state.HaushaltsV.Hausrat.Enable === true) {
            ui_config.active_dropdown_key = 2
        }
        else if (state.HaushaltsV.Privathaftpflicht.Enable === true) {
            ui_config.active_dropdown_key = 3
        }
        else if (state.HaushaltsV.Rechtsschutz.Enable === true) {
            ui_config.active_dropdown_key = 4
        }
    };

    const onChange4 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step4" });
        increments.element_hausrat_2 = true
    };

    const onChange5 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step5" });
        increments.element_hausrat_3 = true
    };

    const onChange6 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step6" });
        increments.element_hausrat_4 = true
    };

    const onChange7 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step7" });
        increments.element_vs_complete = true
    };

    const onChange8 = (checked: any) => {
        ReactGA.send({ hitType: "pageview", page: "/hausrat/step8" });
        increments.element_privathaft_complete = true
    };

    const onChangeCollapse = (key: any) => {
        if (increments.element_general_complete === true) {
            if (key === 1 || key === 2 || key === 3) {
                ui_config.active_dropdown_key = key
            }
            else if (total.TotalOhneStempelMonat > 0 && (key === 5 || key === 4)) {
                ui_config.active_dropdown_key = key
            }
        }

        setState(form.getFieldsValue())
    };

    const onOfferte = (checked: any) => {
        // window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", page: "/hausrat/onOfferte" });

        increments.element_all_complete = true
        ui_config.active_dropdown_key = 5
        setState(form.getFieldsValue())
    };

    const { useBreakpoint } = Grid
    const screens = useBreakpoint()
    const isMobile = screens.sm

    return (
        <Layout>
            <Form
                {...layout}
                form={form}
                initialValues={initContract7S}
                name="nest-messages"
                style={{ backgroundColor: 'rgb(238, 238, 238)', color: 'rgb(238, 238, 238)' }}
                labelAlign="right"
                labelWrap
                colon={false}
                validateMessages={validateMessages}
                onValuesChange={onValuesChange}
                onFinish={(values) => {
                    console.log("onFinish:", values)
                    sendPdf(values)
                }}
            >


                <Header style={{ position: 'fixed', zIndex: 1000, width: '100%', padding: '0px 0px 0px 0px' }} className="header-background">
                    <GlarnerSachHeader title1="Prämienrechner" title2="Haushaltversicherung SIEBENSACHEN" />
                    {(isMobile) &&
                        <>
                            <Row justify="center" className="title" gutter={{ xs: 2, sm: 16, md: 24, lg: 32 }}>
                                <div className="title-non-mobile"><h1>Prämienrechner <br/> Haushaltversicherung SIEBENSACHEN </h1></div>
                            </Row>
                        </>
                    }


                    <Row className="praemie-display">
                        <div className="praemie-display-text">
                            {(total.TotalOhneStempelMonat > 0 &&
                                (!state.HaushaltsV.Hausrat.Enable || (increments.element_vs_complete && state.HaushaltsV.Hausrat.Enable)) &&
                                (!state.HaushaltsV.Privathaftpflicht.Enable || (increments.element_privathaft_complete && state.HaushaltsV.Privathaftpflicht.Enable))) &&
                                <h2 style={{ color: 'rgb(255, 255, 255)' }}>Ihre Prämie: <span className="hide-on-mobile">CHF {total.TotalOhneStempel.toFixed(2)} / Jahr  </span><span style={{ fontSize: 11 }}> - (ca. CHF {total.TotalOhneStempelMonat.toFixed(2)} / Monat)  </span> <Popover content={<>exkl. 5% eidg. Stempelabgabe</>}><InfoCircleOutlined className="pop-info" /></Popover></h2>
                            }
                            {(total.TotalOhneStempelMonat <= 0 ||
                                ((!increments.element_vs_complete && state.HaushaltsV.Hausrat.Enable)) ||
                                ((!increments.element_privathaft_complete && state.HaushaltsV.Privathaftpflicht.Enable))) &&
                                <h2 style={{ color: 'rgb(255, 255, 255)' }}>Ihre Prämie: <span className="hide-on-mobile">CHF xxx / Jahr </span><span style={{ fontSize: 13 }}> - (ca. CHF xx / Monat)  </span> <Popover content={<>exkl. 5% eidg. Stempelabgabe</>}><InfoCircleOutlined className="pop-info" /></Popover></h2>
                            }
                        </div>
                    </Row>
                </Header>

                <Layout style={{}}>

                    <Content className="insurance-content" >
                        <Row justify="center">
                            <Col sm={{ span: 22 }} md={{ span: 18 }} lg={{ span: 14 }} style={{ maxWidth: '980px' }}>
                                <Collapse defaultActiveKey={['1']} ghost accordion onChange={onChangeCollapse} activeKey={ui_config.active_dropdown_key}>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 1 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 1 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>

                                                <Button type="text" onClick={onAllgemein} style={{ height: '50px' }} ><h2>Allgemeine Angaben</h2></Button>
                                            </Col>
                                            <Col span={2}></Col>
                                        </>
                                    }
                                        key="1" showArrow={false}>

                                        <Row justify="center" align="middle">
                                            <h3>Wohnort: <Popover content={<>Gerne prüfen wir, ob Ihr Objekt in unserem Marktgebiet liegt.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                        </Row>

                                        <Row justify="center" align="middle">
                                            <Col span={16}>
                                                <Form.Item name={["Vertragsnehmer", "Plz"]}>
                                                    <DebounceSelect
                                                        // mode="multiple"
                                                        showSearch
                                                        value={{ label: "", value: "", key: "" }}
                                                        placeholder="PLZ"
                                                        onChange={onChange1}
                                                        // onChange={() => { increments.plz = true }}
                                                        // fetchOptions={(search: string): Promise<any> => { return fetch("test") }}
                                                        style={{ maxWidth: '300px' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {(increments.element_general_1 === true) &&
                                            <>
                                                <Row justify="center">
                                                    <h3>Alter: <Popover content={<>Jugendliche bis zum vollendeten 25. Altersjahr profitieren von besonderen Rabatten.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                </Row>

                                                <Row justify="center">
                                                    <Form.Item name={["HaushaltsV", "Alter"]}>
                                                        <InputNumber onChange={onChange2} min={0} max={111} style={{ width: 200 }} />
                                                    </Form.Item>
                                                </Row>
                                            </>}

                                        {(increments.element_general_2 === true) &&
                                            <>
                                                <Row justify="center">
                                                    <h3>Anzahl Personen im Haushalt: <Popover title="Anzahl Personen im Haushalt" content={<>Ob Familie, Freundschaft oder Wohngemeinschaft - sämtliche im selben Haushalt lebenden Personen sind mitversichert.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                </Row>

                                                <Row justify="center" align="middle">
                                                    <Col span={24}>
                                                        <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Personen"]}>
                                                            <Select style={{ width: 120 }} disabled={false} onChange={onChangeGeneralDone}>
                                                                {get_options(params["summenerhebung"].personen_array, false)}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                    </Panel>
                                    <Panel
                                        header={
                                            <>
                                                {ui_config.active_dropdown_key === 2 && (
                                                    <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                        <UpOutlined />
                                                    </div>
                                                )}
                                                {ui_config.active_dropdown_key !== 2 && (
                                                    <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                        <DownOutlined />
                                                    </div>
                                                )}
                                                <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                    <Button type="text" onClick={onChangeHausrat} style={{ height: '50px' }} disabled={!increments.element_general_complete}><h2>Hausratsversicherung</h2></Button>
                                                    <Popover className="pop-info" title="Haushaltsversicherung" content={<>Die Haushaltversicherung versichert Ihr Hab und Gut bei Schäden durch Feuer, Wasser, Diebstahl, Glasbruch und Erdbeben – ob zu Hause oder unterwegs.</>}><InfoCircleOutlined className="pop-info" /></Popover>
                                                </Col>
                                                <Col xs={2}>
                                                    <Form.Item name={["HaushaltsV", "Hausrat", "Enable"]} valuePropName="checked">
                                                        <Switch onChange={onChangeHausrat} disabled={!increments.element_general_complete} />
                                                    </Form.Item>

                                                </Col>
                                            </>}
                                        key="2" showArrow={false}>
                                        <section>
                                            {/* Hausratsversicherung */}
                                            {(increments.element_hausrat_1 === true) &&
                                                <>
                                                    <Row justify="center">
                                                        <h3>Davon Kinder unter 10 Jahre:</h3>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col span={24}>
                                                            <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "NumKinder"]}>
                                                                <Select style={{ width: 120 }} disabled={false} onChange={onChange4}>
                                                                    {Array.from(Array(ui_config.num_person_fields), (e, i) => <Option value={i} key={i}>{i}</Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>}

                                            {(increments.element_hausrat_2 === true) &&
                                                <>
                                                    <Row justify="center">
                                                        <h3>Anzahl Zimmer:</h3>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col span={24}>
                                                            <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Zimmer"]}>
                                                                <Select style={{ width: 120 }} disabled={false} onChange={onChange5}>
                                                                    {getRoomsToSelect((state.HaushaltsV.Hausrat.Summenerhebung.Personen !== null) ? state.HaushaltsV.Hausrat.Summenerhebung.Personen : 0, get_options(params["summenerhebung"].zimmer_array, false))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>}

                                            {(increments.element_hausrat_3 === true) &&
                                                <>
                                                    <Row justify="center">
                                                        <h3>Wohnobjekt:</h3>
                                                    </Row>

                                                    <Row justify="center" align="middle">
                                                        <Col span={24}>
                                                            <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Wohnobjekt"]}>
                                                                <Radio.Group buttonStyle="solid" onChange={onChange6}>
                                                                    <Radio.Button value={GebaeudeTyp.Haus}> <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Haus</Space></Radio.Button>
                                                                    <Radio.Button value={GebaeudeTyp.Wohnung}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Wohnung</Space></Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>}

                                            {(increments.element_hausrat_4 === true) &&
                                                <>
                                                    <Row justify="center">
                                                        <h3>Einrichtungsstandard: <Popover title="Einrichtungsstandard" content={<>Einfach <br /> Vorwiegend leicht ersetzbare Gegenstände. Discount-Möbel aus Abholmärkten. Kleider vorwiegend No-Name-Produkte. Elektronische Gegenstände aus Aktionen und tendenziell älteren Produktlinien. <br /> <br /> Mittel <br /> Vorwiegend Gegenstände aus dem Detailhandel und Möbel meist aus Einrichtungshäuser. Markenkleider im mittleren Preissegment. Elektronische Geräte aus aktuellen Produktlinien. <br /> <br /> Gehoben <br /> Vorwiegend Marken- und Designer-Gegenstände und/oder schwer ersetzbare Einzelstücke. Möbel aus exklusiven Einrichtungshäuser. Markenkleider im mittleren und hohen Preissegment. Elektronische Geräte aus qualitativ hochwertigen Produktlinien.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                    </Row>
                                                    <Row justify="center">
                                                        <Col span={24}>
                                                            <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "EinrichtungsStandard"]}>
                                                                {/* <Input/> */}
                                                                <Radio.Group buttonStyle="solid" onChange={onChange7} style={{}}>
                                                                    <Radio.Button value={EinrichtungsStandard.Einfach}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Einfach</Space></Radio.Button>
                                                                    <Radio.Button value={EinrichtungsStandard.Mittel}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Mittel</Space></Radio.Button>
                                                                    <Radio.Button value={EinrichtungsStandard.Gehoben}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Gehoben</Space></Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item >
                                                        </Col>
                                                    </Row>
                                                </>}

                                            {(increments.element_vs_complete === true) &&
                                                <>
                                                    <div style={{ width: '100%', justifyContent: 'center', backgroundColor: 'rgb(0,41,79)', height: '100px', alignContent: 'center' }}>
                                                        <Row justify="center" style={{ paddingTop: '20px' }}>
                                                            <h3 style={{ fontWeight: 300, color: 'rgb(255,255,255)' }}>Wir haben für Sie folgende Versicherungssumme berechnet:<br /><b>&nbsp;CHF {formatMoney(calculateSumme(state.HaushaltsV.Hausrat.Summenerhebung))}</b> </h3>
                                                            {/* <h3 style={{ fontWeight: 300, color: 'rgb(255,255,255)' }}><b>&nbsp;CHF {formatMoney(calculateSumme(state.HaushaltsV.Hausrat.Summenerhebung))}</b></h3> */}
                                                        </Row>
                                                    </div>

                                                    <Row justify="center">
                                                        <h3>Gerne dürfen Sie diese nach Ihren Bedürfnissen anpassen</h3>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col span={12}>
                                                            <Form.Item name={["HaushaltsV", "Hausrat", "VSumme"]} style={{ minWidth: 150 }}>
                                                                <InputNumber
                                                                    precision={0}
                                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}
                                                                    // parser={value => value!.replace(/\$\s?|(\'*)/g, '')}
                                                                    prefix={'CHF '}
                                                                    max={1000000}
                                                                    controls={false}
                                                                    min={10000}
                                                                    style={{ width: 150 }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>




                                                    <div style={table_style} className="header-boxen">

                                                        <Row justify="center">
                                                            <h3 style={{ lineHeight: '15px' }}>Gegen was möchten Sie <br /> Ihren Hausrat versichern? <Popover content={<>In Ergänzung zu den Grunddeckungen Feuer, Elementar, Wasser und Diebstahl zu Hause.</>}><InfoCircleOutlined className="pop-info" /></Popover> </h3>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right', lineHeight: '15px' }}>
                                                                    <span><h4> <Popover title="Einfacher Diebstahl auswärts" content={<>Die neue Winterjacke wird während dem Aprés-Ski gestohlen, die Handtasche wird in der Menschenmenge unbemerkt entwendet usw. <br /> Bitte beachten Sie, dass Sie über die Haushaltkaskoversicherungen eine Vielzahl an Lieblingsstücken gegen Verlust (und Beschädigung) versichern können.</>}><InfoCircleOutlined className="pop-info" /></Popover> Einfacher Diebstahl <br /> auswärts </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Hausrat", "DiebstahlAuswaerts"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Hausrat", "DiebstahlAuswaertsErstrisiko"]} valuePropName="checked">
                                                                    <Select className="selectWhite" style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Hausrat.DiebstahlAuswaerts} defaultValue={initHausrat.DiebstahlAuswaertsErstrisiko}>
                                                                        {get_options(params.hausratsversicherung.diebstahl_auswaerts.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Glasbruch komplett" content={<>Bruch von Fenstern, Lavabos, Glaskeramik- und Induktionskochfelder, Verglasungen an Möbeln usw. <br /> <br /> Sind Sie Mieter? Dann ist in der Regel die Mobiliarverglasung (Verglasung an Möbeln)  ausreichend. Der Bruch von Fenstern oder Lavabos kann im Mietverhältnis über die Privathaftpflicht versichert werden.</>}><InfoCircleOutlined className="pop-info" /></Popover> Glasbruch komplett </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={11} xs={10}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Hausrat", "Glas"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Mobiliarverglasungen" content={<>Verglasungen an Möbeln</>}><InfoCircleOutlined className="pop-info" /></Popover> Mobiliarverglasung</h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={11} xs={10}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Hausrat", "Mobiliar"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Erdbeben" content={<>Erschütterungen am Hausrat</>}><InfoCircleOutlined className="pop-info" /></Popover> Erdbeben </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={11} xs={10}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Hausrat", "Erdbeben"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row><h3> </h3></Row>
                                                </>}


                                            {(increments.element_vs_complete === true) &&
                                                <>
                                                    <div style={table_style} className="header-boxen">
                                                        <Row justify="center">
                                                            <h3>Haushaltkaskoversicherung: <Popover content={<>Die Haushaltkasko versichert Ihre Lieblingssachen wie Mobiltelefon, Uhr, Bike, Schmuck, Brille und vieles mehr gegen Beschädigung und Verlust.</>}><InfoCircleOutlined className="pop-info" /></Popover></h3>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right', lineHeight: '15px' }}>
                                                                    <span><h4> <Popover title="Haushaltsgeräte und Unterhaltungslekektronik" content={<>Mobiltelefone, Tablets, Fernseher, Spielkonsolen, Foto- und Videokameras, Drohnen usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Haushaltsgeräte und <br /> Unterhaltungslekektronik </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Haushaltskasko", "Haushaltsgeraete"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Haushaltskasko", "HaushaltsgeraeteErstrisiko"]} valuePropName="checked">
                                                                    <Select style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Haushaltskasko.Haushaltsgeraete} defaultValue={initHaushaltkasko.HaushaltsgeraeteErstrisiko}>
                                                                        {get_options(params.haushaltskasko.haushaltsgeraete_unterhaltungselektronik.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Medizinische Hilfsmittel" content={<>Hörgeräte, Rollstühle, Rollatoren, korrigierte Brillen, Insulin Pens usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Medizinische Hilfsmittel </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Haushaltskasko", "MedizinischeHilfsmittel"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Haushaltskasko", "MedizinischeHilfsmittelErstrisiko"]} valuePropName="checked">
                                                                    <Select style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Haushaltskasko.MedizinischeHilfsmittel} defaultValue={initHaushaltkasko.MedizinischeHilfsmittelErstrisiko}>
                                                                        {get_options(params.haushaltskasko.medizinische_hilfsmittel.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Sportgeräte" content={<>Bikes, Ski, Golfausrüstungen, Fintessgeräte, Segway, Elektro-Trotinett usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Sportgeräte</h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Haushaltskasko", "Sportgeraete"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Haushaltskasko", "SportgeraeteErstrisiko"]}>
                                                                    <Select style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Haushaltskasko.Sportgeraete} defaultValue={initHaushaltkasko.SportgeraeteErstrisiko}>
                                                                        {get_options(params.haushaltskasko.sportgeraete.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Reisegepäck" content={<>Schutz des Reisegepäcks während Flugreisen oder auf einer Reise mit mindestens einer Übernachtung.</>}><InfoCircleOutlined className="pop-info" /></Popover> Reisegepäck </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Haushaltskasko", "Reisegepaeck"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Haushaltskasko", "ReisegepaeckErstrisiko"]}>
                                                                    <Select style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Haushaltskasko.Reisegepaeck} defaultValue={initHaushaltkasko.ReisegepaeckErstrisiko}>
                                                                        {get_options(params.haushaltskasko.reisegepaeck.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row justify="center">
                                                            <Col sm={12} xs={13}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right' }}>
                                                                    <span><h4> <Popover title="Wertsachen" content={<>Schmucksachen, Bilder, Kunstgegenstände, Musikinstrumente usw.</>}><InfoCircleOutlined className="pop-info" /></Popover> Wertsachen </h4></span>
                                                                </Space>
                                                            </Col>
                                                            <Col span={1}></Col>
                                                            <Col sm={3} xs={4}>
                                                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left' }}>
                                                                    <Form.Item name={["HaushaltsV", "Haushaltskasko", "Wertsachen"]} valuePropName="checked">
                                                                        <Switch />
                                                                    </Form.Item>
                                                                </Space>
                                                            </Col>
                                                            <Col sm={8} xs={6}>
                                                                <Form.Item name={["HaushaltsV", "Haushaltskasko", "WertsachenErstrisiko"]} valuePropName="checked">
                                                                    <Select style={{ minWidth: 80, paddingTop: 5 }} disabled={!state.HaushaltsV.Haushaltskasko.Wertsachen} defaultValue={initHaushaltkasko.WertsachenErstrisiko}>
                                                                        {get_options(params.haushaltskasko.wertsachen.versicherungssummen, true)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>}
                                        </section>
                                    </Panel>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 3 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 3 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" onClick={onChangePrivathaft} style={{ height: '50px' }} disabled={!increments.element_general_complete}><h2>Privathaftpflicht</h2></Button>
                                                <Popover title="Privathaftpflicht" content={<>Die Privathaftpflicht bietet Versicherungsschutz für Missgeschicke gegenüber Dritten. <br /> <br /> Beispiele: <br /> Das Parkett in der Mietwohnung ist zerkratzt, eine Passantin rutscht auf ihrem Grundstück aus, die geliehene Kamera fällt runter, das geliehene Auto eines Bekannten wird während der Nutzung beschädigt usw.</>}><InfoCircleOutlined className="pop-info" /></Popover>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Enable"]} valuePropName="checked">
                                                    <Switch onChange={onChangePrivathaft} disabled={!increments.element_general_complete} />
                                                </Form.Item>
                                            </Col>
                                        </>}
                                        key="3" showArrow={false}>
                                        {(increments.element_privathaft_1 === true) &&
                                            <>
                                                <Row justify="center">
                                                    <h3>Sind Sie Mieter oder Eigentümer?</h3>
                                                </Row>

                                                <Row justify="center">
                                                    <Col span={24}>
                                                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Besitzverhaeltnis"]}>
                                                            <Radio.Group buttonStyle="solid" onChange={onChange8}>
                                                                <Radio.Button value={Besitzverhaeltnis.Mieter}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Mieter</Space></Radio.Button>
                                                                <Radio.Button value={Besitzverhaeltnis.Eigentuemer}><Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>Eigentümer</Space></Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item >
                                                    </Col>
                                                </Row>
                                            </>}
                                    </Panel>

                                    <Panel header={
                                        <>

                                            <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                <RightOutlined />
                                            </div>
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" onClick={onChangeRechtsschutz} style={{ height: '50px' }} disabled={!increments.element_general_complete}><h2>Rechtsschutz</h2></Button>
                                                <Popover title="Rechtsschutz" content={<>Im Streitfall stehen Ihnen mit der Privat-, Verkehrs- und Immobilienrechtsschutz erfahrene Anwältinnen und Anwälte zur Seite. <br /> <br /> Beispiele: <br /> Privatrechtsschutz <br /> Probleme am Arbeitsplatz, Ärger nach dem Online-Shopping, Streitigkeiten mit dem Vermieter usw. <br /> <br /> Verkehrsrechtsschutz <br /> Streitigkeiten nach einem Verkehrsunfall, Ärger mit der Versicherung aufgrund eines Schadenfalls usw. <br /> <br /> Immobilienrechtsschutz <br /> Die Gemeinschaft der Stockwerkeigentümer stellt willkürlich Kosten in Rechnung, ein Nachbar stört sich plötzlich an ihrem alten Kirschbaum usw.</>}><InfoCircleOutlined className="pop-info" /></Popover>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Item name={["HaushaltsV", "Rechtsschutz", "Enable"]} valuePropName="checked">
                                                    <Switch onChange={onChangeRechtsschutz} disabled={!increments.element_general_complete} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    }
                                        key="6" showArrow={false}>
                                    </Panel>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 4 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 4 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" onClick={onZusammenstellung} style={{ height: '50px' }} disabled={!increments.element_general_complete || insurance.not}><h2>Zusammenstellung</h2></Button>
                                            </Col>
                                            <Col span={2}></Col>
                                        </>
                                    }
                                        key="4" showArrow={false}>


                                        <Row style={{ background: 'rgb(238, 238, 238)', color: 'rgb(0, 0, 0)', marginBottom: '10px', padding: '6px 6px 6px 6px', fontSize: '16px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <span>Hausrat</span>
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.Hausrat.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row style={{ background: 'rgb(238, 238, 238)', color: 'rgb(0, 0, 0)', marginBottom: '10px', padding: '6px 6px 6px 6px', fontSize: '16px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Haushaltskasko
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.HaushaltsKasko.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(238, 238, 238)', color: 'rgb(0, 0, 0)', marginBottom: '10px', padding: '6px 6px 6px 6px', fontSize: '16px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Privathaftpflicht
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.Privathaft.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(238, 238, 238)', color: 'rgb(0, 0, 0)', marginBottom: '10px', padding: '6px 6px 6px 6px', fontSize: '16px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Rechtsschutz
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.Rechtsschutz.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        {(total.TotalRabatt > 0) && <>
                                            <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px' }}>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'left' }}>
                                                        Total Prämien
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span>{total.TotalPraemien.toFixed(2)}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'left' }}>
                                                        Rabatt
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ alignContent: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span>{total.TotalRabatt.toFixed(2)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        }

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Total ohne Stempelabgabe
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.TotalOhneStempel.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px', borderBottom: 'solid 1px black' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    Eidg. Stempelabgabe
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span>{total.Stempel.toFixed(2)}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', marginBottom: '10px', fontSize: '16px', padding: '0px 6px 0px 6px' }}>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <b>Total Jahresprämien</b>
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ alignContent: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <b><span>{total.TotalMitStempel.toFixed(2)}</span></b>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row justify="center" style={{ paddingTop: 10 }}>
                                            <Button onClick={onOfferte} style={{ filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))', height: '50px', width: '250px' }} type="primary">OFFERTE ANFORDERN</Button>
                                        </Row>
                                    </Panel>
                                    <Panel header={
                                        <>
                                            {ui_config.active_dropdown_key === 5 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <UpOutlined />
                                                </div>
                                            )}
                                            {ui_config.active_dropdown_key !== 5 && (
                                                <div style={{ textAlign: "right", marginTop: 8, fontSize: 18, width: 12 }} className={"collapseArrow"}>
                                                    <DownOutlined />
                                                </div>
                                            )}
                                            <Col xs={22} sm={14} lg={9} style={{ minWidth: '280px', maxWidth: '400px' }}>
                                                <Button type="text" onClick={onKontakt} style={{ height: '50px' }} disabled={!increments.element_general_complete || insurance.not}><h2>Kontaktangaben</h2></Button>
                                            </Col>
                                            <Col span={2}></Col>
                                        </>
                                    } key="5" showArrow={false}>
                                        <Divider className="nehmerDivider">Angaben Versicherungsnehmer</Divider>
                                        <Row justify="center">
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Anrede"]}
                                                    rules={[{ required: true, message: 'Bite wählen Sie Ihre Anrede aus.' }]}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Select placeholder="Anrede" style={{ width: '100%' }}>
                                                        <Select.Option key="Frau">Frau</Select.Option>
                                                        <Select.Option key="Herr">Herr</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Vorname"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihren Vornamen an.' }]}
                                                >
                                                    <Input placeholder="Vorname" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Nachname"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihren Namen an.' }]}
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Person", "Geburtsdatum"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihr Geburtsdatum an: TT.MM.JJJJ', validator: checkDate }]}
                                                >
                                                    <Input placeholder="Geburtsdatum" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Strasse"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihre Adresse an.' }]}
                                                >
                                                    <Input placeholder="Adresse" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Plz"]}
                                                    rules={[{ required: true, message: 'Bitte geben Sie Ihre PLZ an.' }]}>
                                                    <DebounceSelect
                                                        // mode="multiple"
                                                        // showSearch
                                                        value={{ label: "", value: "", key: "" }}
                                                        placeholder="PLZ Ort"
                                                        onChange={onChange1}
                                                        //onChange={() => { increments.plz = true }}
                                                        // fetchOptions={(search: string): Promise<any> => { return fetch("test") }}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Firma"]}
                                                >
                                                    <Input placeholder="Firma" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "Email"]}
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: ''
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Bitte geben Sie Ihre E-Mail an',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="E-Mail" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    name={["Vertragsnehmer", "NetzNr"]}
                                                    rules={[
                                                        {
                                                            message: 'Telefonnummer',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Bitte geben Sie Ihre Telefonnummer an',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Telefonnummer"
                                                        style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {(ui_config.num_person_fields > 1) &&
                                            <>
                                                <Divider>Angaben zu den mitversicherten Personen</Divider>
                                                <Row justify="center">
                                                    <Col xs={22} sm={16}>
                                                        <div>
                                                            Person 1:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person1", "Vorname"]}
                                                            rules={[{ required: true, message: 'Vorname' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person1", "Nachname"]}
                                                            rules={[{ required: true, message: 'Name' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person1", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Geburtsdatum: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(ui_config.num_person_fields > 2) &&
                                            <>
                                                <Row justify="center">
                                                    <Col xs={22} sm={16}>
                                                        <div>
                                                            Person 2:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person2", "Vorname"]}
                                                            rules={[{ required: true, message: 'Vorname' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person2", "Nachname"]}
                                                            rules={[{ required: true, message: 'Name' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person2", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Geburtsdatum: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(ui_config.num_person_fields > 3) &&
                                            <>
                                                <Row justify="center">
                                                    <Col xs={22} sm={16}>
                                                        <div>
                                                            Person 3:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person3", "Vorname"]}
                                                            rules={[{ required: true, message: 'Vorname' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person3", "Nachname"]}
                                                            rules={[{ required: true, message: 'Name' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person3", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Geburtsdatum: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(ui_config.num_person_fields > 4) &&
                                            <>
                                                <Row justify="center">
                                                    <Col xs={22} sm={16}>
                                                        <div>
                                                            Person 4:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person4", "Vorname"]}
                                                            rules={[{ required: true, message: 'Vorname' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person4", "Nachname"]}
                                                            rules={[{ required: true, message: 'Name' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person4", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Geburtsdatum: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(ui_config.num_person_fields > 5) &&
                                            <>
                                                <Row justify="center">
                                                    <Col xs={22} sm={12}  >
                                                        <div className="marktgebietWarning">
                                                            Person 5:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person5", "Vorname"]}
                                                            rules={[{ required: true, message: 'Vorname' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person5", "Nachname"]}
                                                            rules={[{ required: true, message: 'Name' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person5", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Geburtsdatum: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        {(ui_config.num_person_fields > 6) &&
                                            <>
                                                <Row justify="center">
                                                    <Col xs={22} sm={16}>
                                                        <div>
                                                            Person 6:
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row justify="start">
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person6", "Vorname"]}
                                                            rules={[{ required: true, message: 'Bitte geben Sie Ihren Vornamen an.' }]}
                                                        >
                                                            <Input placeholder="Vorname" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person6", "Nachname"]}
                                                            rules={[{ required: true, message: 'Bitte geben Sie Ihren Namen an.' }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24}>
                                                        <Form.Item
                                                            name={["Person6", "Geburtsdatum"]}
                                                            rules={[{ required: true, message: 'Bitte geben Sie Ihr Geburtsdatum an: TT.MM.JJJJ', validator: checkDate }]}
                                                        >
                                                            <Input placeholder="Geburtsdatum" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>}

                                        <Row justify="center">
                                            {isSendInProgress ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
                                                <Button htmlType="submit" style={{ filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))', height: '50px', width: '250px' }} type="primary">OFFERTE ANFORDERN</Button>}
                                        </Row>
                                        {(isSendInProgress) &&
                                            <Row justify="center">
                                                <Col xs={22} sm={16}>
                                                    <div>
                                                        Ihre Daten werden verarbeitet. Bitte haben Sie einen Augenblick Geduld.
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Form.Item name={["HaushaltsV", "Hausrat", "Enable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Enable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Rechtsschutz", "Enable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "Enable"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["Vertragsnehmer", "Plz"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Alter"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Personen"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "NumKinder"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Zimmer"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Summenerhebung", "Wohnobjekt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "VSumme"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "DiebstahlAuswaerts"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "DiebstahlAuswaertsErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Glas"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Mobiliar"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "Erdbeben"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "Haushaltsgeraete"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "HaushaltsgeraeteErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "MedizinischeHilfsmittel"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "MedizinischeHilfsmittelErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "Sportgeraete"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "SportgeraeteErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "Reisegepaeck"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "ReisegepaeckErstrisiko"]}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "Wertsachen"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "WertsachenErstrisiko"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Auto"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Besitzverhaeltnis"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "Erstrisiko"]} style={{ display: 'none' }}></Form.Item>

                        <Form.Item name={["HaushaltsV", "Hausrat", "PersonenArt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Haushaltskasko", "PersonenArt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Privathaftpflicht", "PersonenArt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Rechtsschutz", "PersonenArt"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["HaushaltsV", "Hausrat", "VSumme"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["OffertenNr"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["VertragsBeginn"]} style={{ display: 'none' }}></Form.Item>
                        <Form.Item name={["DatumHeute"]} style={{ display: 'none' }}></Form.Item>
                    </Content>

                </Layout>
            </Form >
        </Layout >
    );
};

export default Hausratsversicherung;
