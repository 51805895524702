import params from "../../data/parameters.json";
import { Besitzverhaeltnis } from "../../models/Enums";
import PrivathaftpflichtType from "../../models/Privathaftpflicht";
import { PersonenArt } from "../../models/Enums";

const calculateTotalPrivathaftpflicht = (obj: PrivathaftpflichtType) => {

    let praemie = 0
    let current_params = params.privathaftpflicht

    if (obj.Auto != null &&
        obj.Besitzverhaeltnis != null &&
        obj.Erstrisiko != null &&
        obj.PersonenArt != null) {

        let versicherungssumme = obj.Erstrisiko / 1000
        let mit_auto = obj.Auto
        let besitzverhaeltnis = obj.Besitzverhaeltnis

        if (obj.Enable) {
            if (versicherungssumme < 10000) {
                if (besitzverhaeltnis === Besitzverhaeltnis.Eigentuemer) {
                    if (mit_auto) {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_5m.eigentuemer.mit_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_5m.eigentuemer.mit_auto.einzelperson
                        }
                    }
                    else {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_5m.eigentuemer.ohne_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_5m.eigentuemer.ohne_auto.einzelperson
                        }
                    }
                }
                else {
                    if (mit_auto) {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_5m.mieter.mit_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_5m.mieter.mit_auto.einzelperson
                        }
                    }
                    else {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_5m.mieter.ohne_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_5m.mieter.ohne_auto.einzelperson
                        }
                    }
                }
            }
            else {
                if (besitzverhaeltnis === Besitzverhaeltnis.Eigentuemer) {
                    if (mit_auto) {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_10m.eigentuemer.mit_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_10m.eigentuemer.mit_auto.einzelperson
                        }
                    }
                    else {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_10m.eigentuemer.ohne_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_10m.eigentuemer.ohne_auto.einzelperson
                        }
                    }
                }
                else {
                    if (mit_auto) {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_10m.mieter.mit_auto.mehrperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_10m.mieter.mit_auto.einzelperson
                        }
                    }
                    else {
                        if (obj.PersonenArt === PersonenArt.Mehrperson) {
                            praemie = current_params.versicherungssumme_10m.mieter.ohne_auto.einzelperson
                        }
                        else {
                            praemie = current_params.versicherungssumme_10m.mieter.ohne_auto.mehrperson
                        }
                    }
                }
            }
        }
    }

    return { "totalPraemie": praemie }
}

export default calculateTotalPrivathaftpflicht