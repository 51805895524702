import HauskaskoType from "../models/Haushaltkasko"
import params from "../data/parameters.json"
import { PersonenArt } from "../models/Enums"

const initHaushaltkasko = {
    Enable: true,//params.haushaltskasko.default_enable,
    PersonenArt: null,

    Haushaltsgeraete: params.haushaltskasko.haushaltsgeraete_unterhaltungselektronik.default_enable,
    HaushaltsgeraeteErstrisiko: params.haushaltskasko.haushaltsgeraete_unterhaltungselektronik.versicherungssumme_default * params.general_params.umrechungsfaktor,
    MedizinischeHilfsmittel: params.haushaltskasko.medizinische_hilfsmittel.default_enable,
    MedizinischeHilfsmittelErstrisiko: params.haushaltskasko.medizinische_hilfsmittel.versicherungssumme_default * params.general_params.umrechungsfaktor,
    Reisegepaeck: params.haushaltskasko.reisegepaeck.default_enable,
    ReisegepaeckErstrisiko: params.haushaltskasko.reisegepaeck.versicherungssumme_default * params.general_params.umrechungsfaktor,
    Sportgeraete: params.haushaltskasko.sportgeraete.default_enable,
    SportgeraeteErstrisiko: params.haushaltskasko.sportgeraete.versicherungssumme_default * params.general_params.umrechungsfaktor,
    Wertsachen: params.haushaltskasko.wertsachen.default_enable,
    WertsachenErstrisiko: params.haushaltskasko.wertsachen.versicherungssumme_default * params.general_params.umrechungsfaktor,
} as HauskaskoType

export default initHaushaltkasko