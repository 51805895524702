import params from "../../data/parameters.json";
import { PersonenArt } from "../../models/Enums";
import roundTo5 from "../utils/round_to5";
import ValueInInterval from "../ValueInInterval"

const calculateWertsachen = (erstrisiko: number, personen_art: PersonenArt) => {

    erstrisiko /= 1000

    let current_params = params.haushaltskasko.wertsachen
    let tarif = 0

    if (personen_art === PersonenArt.Mehrperson) {
        tarif = ValueInInterval(erstrisiko, current_params.tarife_mehrperson, current_params.tarifstufen)
    }
    else {
        tarif = ValueInInterval(erstrisiko, current_params.tarife_einzelperson, current_params.tarifstufen)
    }

    let praemie = roundTo5(tarif * erstrisiko)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateWertsachen