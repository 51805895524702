import { isNumber } from "lodash";
import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";

const calculateGlas = () => {

    let params_glas = params.hausratsversicherung.glas
    let praemie = 0

    praemie = params_glas.pauschalpraemie

    praemie = roundTo5(praemie)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateGlas