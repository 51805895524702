import params from "../data/parameters.json"

import roundTo5 from "./utils/round_to5";
import { GebaeudeType3D } from "../models/GebaeudeType3D";
import calculateFeuerElementar3D from "./3d_gebaeude/FeuerElementar3DCalculator";
import calculateWasser3D from "./3d_gebaeude/Wasser3DCalculator";
import calculateUmgebungsschaedenFeuerElementar3D from "./3d_gebaeude/UmgebungsschaedenFeuerElementar3DCalculator";
import calculateDiebstahl3D from "./3d_gebaeude/Diebstahl3DCalculator";
import calculateGlasbruch3D from "./3d_gebaeude/Glasbruch3DCalculator";
import calculateErdbeben3D from "./3d_gebaeude/Erdbeben3DCalculator";
import calculateExtendedCoverage3D from "./3d_gebaeude/ExtendedCoverage3DCalculator";
import { ContractRates3DType } from "../models/ContractRates3DType";
import calculateGebaeudetechnik3D from "./3d_gebaeude/Gebaeudetechnik3DCalculator";
import { BewohnenArt } from "../models/Enums";


export const computeTotal3D = (obj: GebaeudeType3D) => {
    let feuer_praemie = 0
    let feuer_umgebung_praemie = 0
    let diebstahl_praemie = 0
    let wasser_praemie = 0
    let glas_praemie = 0
    let erdbeben_praemie = 0
    let extended_praemie = 0
    let gebaeudetechnik_praemie = 0
    let total_praemien = 0
    let total_rabatt = 0

    if (obj.BewohnenArt != null) {

        wasser_praemie = calculateWasser3D(obj.Vollwert).praemie

        if (obj.BewohnenArt === BewohnenArt.NichtDauernd) {
            wasser_praemie = wasser_praemie * 1.3
        }


        if (obj.FeuerElementarEnable) {
            feuer_praemie = calculateFeuerElementar3D(obj.Vollwert, obj.BewohnenArt).praemie
        }

        if (obj.UmgebungsschaedenFeuerElementarEnable) {
            feuer_umgebung_praemie = calculateUmgebungsschaedenFeuerElementar3D(obj.UmgebungsschaedenFeuerElementarErstrisiko).praemie
        }

        if (obj.DiebstahlEnable) {
            diebstahl_praemie = calculateDiebstahl3D(obj.Vollwert).praemie
        }

        if (obj.GlasbruchEnable) {
            glas_praemie = calculateGlasbruch3D(obj.GlasbruchErstrisiko, obj.Vollwert).praemie
        }

        if (obj.ErdbebenEnable) {
            erdbeben_praemie = calculateErdbeben3D(obj.Vollwert).praemie
        }

        if (obj.ExtendedCoverageEnable) {
            extended_praemie = calculateExtendedCoverage3D(obj.Vollwert).praemie
        }

        if (obj.GebaeudetechnikEnable) {
            gebaeudetechnik_praemie = calculateGebaeudetechnik3D(obj.Vollwert, obj.GebaeudetechnikErstrisiko).praemie
        }
    }
    let single_rates = {
        "Feuer": feuer_praemie,
        "FeuerUmgebung": feuer_umgebung_praemie,
        "Diebstahl": diebstahl_praemie,
        "Wasser": wasser_praemie,
        "Glas": glas_praemie,
        "Erdbeben": erdbeben_praemie,
        "Extended": extended_praemie,
        "Gebaeude": gebaeudetechnik_praemie
    }

    total_praemien =
        feuer_praemie +
        feuer_umgebung_praemie +
        diebstahl_praemie +
        wasser_praemie +
        glas_praemie +
        erdbeben_praemie +
        extended_praemie +
        gebaeudetechnik_praemie

    total_rabatt += params.general_params.prozent_rabatt_3D * (total_praemien - total_rabatt) + params.general_params.pauschal_rabatt_3D

    let total_ohne_stempel = roundTo5(total_praemien - total_rabatt)
    let eidg_stempel = roundTo5(total_ohne_stempel * params.general_params.eidg_stempelabgabe)
    let total_mit_stempel = roundTo5(total_ohne_stempel + eidg_stempel)

    return {
        "EinzelRates": single_rates,
        "TotalPraemien": total_praemien,
        "TotalRabatt": total_rabatt,
        "TotalOhneStempel": total_ohne_stempel,
        "TotalOhneStempelMonat": roundTo5(total_ohne_stempel / 12),
        "Stempel": eidg_stempel,
        "TotalMitStempel": total_mit_stempel
    } as ContractRates3DType
}

export default computeTotal3D