import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd"
import { Footer } from "antd/lib/layout/layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'



const GlarnerSachFooter = () => {
    return (
        <Footer style={{ background: "#0b2d52", marginTop: 100, color: 'white', width: '100%', padding: '25px 0px 20px 0px'  }}>
            <Row style={{ justifyContent: 'center',width: '100%'}}>
                <Col span={22} style={{ maxWidth: '980px' }}>
                    <Row justify="start" >
                        <Col md={{ span: 8 }} xs={{ span: 24 }} style={{ fontWeight: 300, paddingBottom: '10px' }}>
                            <b>KONTAKT</b><br />
                            glarnerSach<br />
                            Zwinglistrasse 6 · Postfach<br />
                            8750 Glarus<br /><br />

                            <span>Telefon <Button block={true} className="social-media-footer-button" type="link" href="tel:+41556456161" style={{ height: '10px', width: '35px', paddingLeft: '0px', paddingTop: '0px', paddingBottom: '0px', fontWeight: 300}}><span className="footer-underline-button">+41 (0)55 645 61 61</span></Button> </span>
                            <br/>
                            <Button block={true} className="social-media-footer-button" type="link" href="mailto:info@glarnersach.ch" style={{ height: '10px', width: '35px', paddingLeft: '0px', paddingTop: '0px', paddingBottom: '0px',  fontWeight: 300 }}> <span className="footer-underline-button">info@glarnersach.ch</span></Button>
                            
                        </Col>
                        <Col className="gutter-row" md={{ span: 6 }} xs={{ span: 0 }} style={{ justifyContent: 'right' }}>
                        </Col>
                        <Col className="gutter-row" md={{ span: 6 }} xs={{ span: 12 }} style={{ justifyContent: 'left' }}>
                            <Row style={{ height: '20px', paddingTop: '0px' }}><Button size='small' className="social-media-link" type="link" href="https://glarnersach.typowerkstatt.ch/impressum" style={{ fontWeight: 300, paddingLeft: 0 }}> <span className="footer-underline-button">Impressum</span></Button></Row>
                            <Row style={{ height: '20px' }}><Button size='small' className="social-media-link" type="link" href="https://glarnersach.typowerkstatt.ch/datenschutz" style={{ fontWeight: 300, paddingLeft: 0 }}><span className="footer-underline-button">Datenschutz</span></Button></Row>
                        </Col>
                        <Col className="gutter-row" md={{ span: 4 }} xs={{ span: 12 }}>
                            <Row style={{ justifyContent: 'right' }}>
                                {/* <InstagramOutlined style={{ fontSize: 28 }}  /> */}
                                <Button className="social-media-link" type="link" href="https://www.facebook.com/glarnersach" style={{ height: '20px', width: '20px' }} target={"_blank"} icon={<FontAwesomeIcon icon={brands('facebook-square')} style={{ fontSize: 20, }} />} />
                                <div style={{ width: '5px' }}></div>
                                <Button className="social-media-link" type="link" href="https://www.instagram.com/glarnersach/" style={{ height: '20px', width: '20px' }} target={"_blank"} icon={<FontAwesomeIcon icon={brands('instagram')} style={{ fontSize: 20 }} />} />
                                <div style={{ width: '5px' }}></div>
                                <Button className="social-media-link" type="link" href="https://www.youtube.com/user/glarnersach" style={{ height: '20px', width: '20px' }} target={"_blank"} icon={<FontAwesomeIcon icon={brands('youtube')} style={{ fontSize: 20 }} />} />
                                <div style={{ width: '10px' }}></div>
                                <Button className="social-media-link" type="link" href="https://www.linkedin.com/company/12268694" style={{ height: '20px', width: '20px' }} target={"_blank"} icon={<FontAwesomeIcon icon={brands('linkedin-in')} style={{ fontSize: 20 }} />} />
                            </Row>
                        </Col>
                        {/* <Col md={{ span: 3 }} xs={{ span: 0 }} /> */}
                    </Row>
                </Col>
            </Row>
        </Footer>)

}


export default GlarnerSachFooter;