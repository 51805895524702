import initContractTaker from "./InitContractTaker"
import params from "../data/parameters.json"
import { Contract3DType } from "../models/Contract3DType"
import initialGebaeude3D from "./InitGebaeude3D"

let current_date = new Date(Date.now());
let start_date = new Date(Date.now());
start_date.setMonth(start_date.getMonth() + 1)
start_date.setDate(1)


const initContract3D = {
    OffertenNr: null,
    VertragsBeginn: start_date as Date,
    DatumHeute: current_date as Date,
    Vertragsnehmer: initContractTaker,
    GebaeudeV: initialGebaeude3D
} as Contract3DType

export default initContract3D