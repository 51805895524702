import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"
import { PersonenArt } from "../../models/Enums";
import roundTo5 from "../utils/round_to5";

const calculateDiebstahlAuswaertz = (erstrisiko: number, personen_art: PersonenArt) => {

    let params_diebstahl = params.hausratsversicherung.diebstahl_auswaerts

    erstrisiko /= 1000
    let tarif = 0

    if (personen_art === PersonenArt.Mehrperson) {
        tarif = ValueInInterval(
            erstrisiko,
            params_diebstahl.tarife_mehrperson,
            params_diebstahl.tarifstufen)
    }
    else {
        tarif = ValueInInterval(
            erstrisiko,
            params_diebstahl.tarife_einperson,
            params_diebstahl.tarifstufen)
    }

    let praemie = roundTo5(tarif * erstrisiko)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt":  rabatt}
}

export default calculateDiebstahlAuswaertz