import HausratsType from "../../models/Hausrat";
import calculateFeuerElementar from "./FeuerElementarCalculator"
import calculateDiebstahl from "./DiebstahlCalculator";
import calculateDiebstahlAuswaertz from "./DiebstahlAuswaetzCalculator";
import calculateWasser from "./WasserCalculator";
import calculateGlas from "./GlasCalculator";
import calculateErdbeben from "./ErdbebenCalculator";
import calculateMobiliar from "./MobiliarCalculator";


export const computeTotalHausrat = (obj: HausratsType) => {



    let total_praemie = 0
    let total_rabatt = 0
    let feuer_praemie = 0
    let diebstahl_praemie = 0
    let diebstahl_auswaertz_praemie = 0
    let wasser_praemie = 0
    let glas_praemie = 0
    let mobiliar_praemie = 0
    let erdbeben_praemie = 0

    if (obj.PersonenArt != null && obj.VSumme !== 0) {
        let feuer_cond = calculateFeuerElementar(obj.VSumme)
        let dieb_cond = calculateDiebstahl(obj.VSumme)
        let dieb_aus_cond = calculateDiebstahlAuswaertz(obj.DiebstahlAuswaertsErstrisiko, obj.PersonenArt)
        let wasser_cond = calculateWasser(obj.VSumme)
        let glas_cond = calculateGlas()
        let mobiliar_cond = calculateMobiliar()
        let erdbeben_cond = calculateErdbeben(obj.VSumme)

        if (obj.Enable) {

            total_rabatt =
                feuer_cond.rabatt +
                dieb_cond.rabatt +
                wasser_cond.rabatt

            feuer_praemie = feuer_cond.praemie
            diebstahl_praemie = dieb_cond.praemie
            wasser_praemie = wasser_cond.praemie

            if (obj.DiebstahlAuswaerts) {
                diebstahl_auswaertz_praemie = dieb_aus_cond.praemie
                total_rabatt += dieb_aus_cond.rabatt
            }

            if (obj.Glas) {
                glas_praemie = glas_cond.praemie
                total_rabatt += glas_cond.rabatt
            }

            if (obj.Mobiliar) {
                mobiliar_praemie = mobiliar_cond.praemie
                total_rabatt += mobiliar_cond.rabatt
            }

            if (obj.Erdbeben) {
                erdbeben_praemie = erdbeben_cond.praemie
                total_rabatt += erdbeben_cond.rabatt
            }

            total_praemie =
                feuer_praemie +
                diebstahl_praemie +
                diebstahl_auswaertz_praemie +
                wasser_praemie +
                glas_praemie +
                mobiliar_praemie +
                erdbeben_praemie
        }
    }

    return {
        "totalPraemie": total_praemie,
        "totalRabatt": total_rabatt,
        "feuerPraemie": feuer_praemie,
        "diebPramie": diebstahl_praemie,
        "diebAPraemie": diebstahl_auswaertz_praemie,
        "wasserPraemie": wasser_praemie,
        "glasPraemie": glas_praemie,
        "mGlasPraemie": mobiliar_praemie,
        "erdbebenPraemie": erdbeben_praemie
    }
}

export default computeTotalHausrat