import { HaushaltsType7S } from "../models/HaushaltsType"
import initHausrat from "./InitHausrat"
import initHaushaltkasko from "./InitHaushaltskasko"
import initPrivathaft from "./InitPrivathaftpflicht"
import initRechtsschutz from "./InitRechtsschutz"

const initialHaushaltsType7S = {
    Alter: 0,
    Privathaftpflicht: initPrivathaft,
    Hausrat: initHausrat,
    Haushaltskasko: initHaushaltkasko,
    Rechtsschutz: initRechtsschutz
} as HaushaltsType7S

export default initialHaushaltsType7S