import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"
import roundTo5 from "../utils/round_to5";

const calculateFeuerElementar = (vollwert: number) => {

    let params_feuer = params.hausratsversicherung.feuer_elementar

    vollwert /= 1000


    let tarif = ValueInInterval(
        vollwert,
        params_feuer.tarife,
        params_feuer.tarifstufen)

    let praemie = roundTo5(tarif * vollwert)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt}
}

export default calculateFeuerElementar