import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"

const calculateExtendedCoverage3D = (vollwert: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.extended_coverage
    let praemie = 0

    praemie = ValueInInterval(vollwert, current_params.tarife, current_params.tarifstufen)

    if (praemie < 0) {
        praemie = 0
    }

    let rabatt = 0


    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateExtendedCoverage3D