import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"

const calculateGebaeudetechnik3D = (vollwert: number, erstrisiko: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.gebaeudetechnik
    let praemie = 0

    switch (erstrisiko) {
        case 10000:
            praemie = ValueInInterval(vollwert, current_params.praemien_10k, current_params.tarifstufen)
            break;
        case 20000:
            praemie = ValueInInterval(vollwert, current_params.praemien_20k, current_params.tarifstufen)
            break;
        case 50000:
            praemie = ValueInInterval(vollwert, current_params.praemien_50k, current_params.tarifstufen)
            break;
    }

    if(praemie < 0) {
        praemie = 0
    }

    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateGebaeudetechnik3D