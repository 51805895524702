const getLinkedValueInInterval = (value: number, values: number[], interval_bounds: number[]) => {

    let return_value = -1

    for (let i = 0; i < values.length; i++) {
        if (value > interval_bounds[i] && value <= interval_bounds[i + 1]) {
            return_value = values[i]
        }
    }

    return return_value;
}

export default getLinkedValueInInterval