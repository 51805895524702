import { ContractTakerType } from "../models/ContractTakerType"

const initContractTaker = {
    Anrede: null,
    Person: null,
    Email: null,
    NetzNr: null,
    Strasse: null,
    Plz: null,
    Wohnort: null,
    Firma: null,
} as ContractTakerType

export default initContractTaker