import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"
import roundTo5 from "../utils/round_to5";

const calculateDiebstahl = (vollwert: number) => {

    let params_diebstahl = params.hausratsversicherung.diebstahl

    vollwert /= 1000

    let tarif = ValueInInterval(
        vollwert,
        params_diebstahl.tarife,
        params_diebstahl.tarifstufen)

    let praemie = roundTo5(tarif * vollwert)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateDiebstahl