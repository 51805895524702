import { GebaeudeType3D } from "../models/GebaeudeType3D"

const initialGebaeude3D = {
    Vollwert: 0,
    BewohnenArt: null,
    FeuerElementarEnable: true,
    UmgebungsschaedenFeuerElementarEnable: true,
    UmgebungsschaedenFeuerElementarErstrisiko: 10000,
    DiebstahlEnable: false,
    ErdbebenEnable: false,
    GlasbruchEnable: false,
    GlasbruchErstrisiko: 10000,
    ExtendedCoverageEnable: false,
    GebaeudetechnikEnable: false,
    GebaeudetechnikErstrisiko: 20000,
} as GebaeudeType3D

export default initialGebaeude3D