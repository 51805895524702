import Marktgebiet from "./Marktgebiet.json"

const checkMarktgebiet = (plz: number) => {
    let found = false
    let gustavo = false

    for (let i = 0; i < Marktgebiet.length; i++) {
        let current_data = Marktgebiet[i]
        if (current_data.Plz === plz) {
            found = true

            if (current_data.IsGustavo === 'Ja') {
                gustavo = true
            }
        }
    }

    return { Found: found, IsGustavo: gustavo }
}

export { checkMarktgebiet }