import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";

const calculateWasser = (vollwert: number) => {

    vollwert /= 1000

    let params_wasser = params.hausratsversicherung.wasser
    let praemie = roundTo5(params_wasser.tarif * vollwert)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateWasser