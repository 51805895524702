import PrivathaftpflichtType from "../models/Privathaftpflicht"
import { Besitzverhaeltnis, PersonenArt } from "../models/Enums"
import params from "../data/parameters.json"

const initPrivathaft = {
    PersonenArt: null,
    Enable: params.privathaftpflicht.default_enable,
    Auto: true,
    Besitzverhaeltnis: null,
    Erstrisiko: 5000000
} as PrivathaftpflichtType

export default initPrivathaft