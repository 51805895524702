import params from "../../data/parameters.json";
import { BewohnenArt } from "../../models/Enums";
import roundTo5 from "../utils/round_to5";

const calculateFeuerElementar3D = (vollwert: number, bewohnen_art: BewohnenArt) => {
    
    vollwert /= 1000

    let current_params = params.gebaeude.feuer_elementar
    let tarif = 0

    if (bewohnen_art === BewohnenArt.Dauernd) {
        tarif = current_params.tarife.dauernd_bewohnt
    }
    else {
        tarif = current_params.tarife.nicht_dauernd_bewohnt
    }

    let praemie = roundTo5(tarif * vollwert)
    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateFeuerElementar3D