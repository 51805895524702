import HausratsType from "../models/Hausrat"
import params from "../data/parameters.json"
import { SummenerhebungType } from "../models/SummenerhebungType"

const initHausrat = {
    Enable: params.hausratsversicherung.default_enable as boolean,

    Summenerhebung: {
        Personen: null,
        Zimmer: null,
        NumKinder: null,
        EinrichtungsStandard: null,
        Wohnobjekt: null
    } as SummenerhebungType,

    VSumme: 0,
    PersonenArt: null,

    Erdbeben: params.hausratsversicherung.erdbeben.default_enable as boolean,
    DiebstahlAuswaerts: params.hausratsversicherung.diebstahl_auswaerts.default_enable as boolean,
    DiebstahlAuswaertsErstrisiko: params.hausratsversicherung.diebstahl_auswaerts.versicherungssumme_default * params.general_params.umrechungsfaktor as number,
    Mobiliar: params.hausratsversicherung.mobiliar.default_enable as boolean,
    Glas: params.hausratsversicherung.glas.default_enable as boolean,
    GlasErstrisiko: params.hausratsversicherung.glas.versicherungssumme_default * params.general_params.umrechungsfaktor as number,

} as HausratsType

export default initHausrat






