import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";
import ValueInInterval from "../ValueInInterval"

const calculateUmgebungsschaedenFeuerElementar3D = (erstrisiko: number) => {

    erstrisiko /= 1000

    let current_params = params.gebaeude.umgebung_feuer_elementar
    let tarif = 0

    tarif = ValueInInterval(erstrisiko, current_params.tarife, current_params.tarifstufen)

    if (tarif < 0) {
        tarif = 0
    }

    let praemie = tarif
    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateUmgebungsschaedenFeuerElementar3D