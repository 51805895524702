export enum Besitzverhaeltnis {
    Eigentuemer = "eigentuemer",
    Mieter = "mieter"
}

export enum GebaeudeTyp {
    Haus = "haus",
    Wohnung = "wohnung"
}

export enum EinrichtungsStandard {
    Einfach = "einfach",
    Mittel = "mittel",
    Gehoben = "gehoben"
}

export enum KonaktaufnahmeArt {
    Email = "email",
    Telefon = "telefon",
    Whatsapp = "whatsapp"
}

export enum PersonenArt {
    Mehrperson = "mehrpersonen",
    Einperson = "einperson"
}

export enum BewohnenArt {
    Dauernd = "dauernd",
    NichtDauernd = "nichtDauernd"
}