import React from "react";
import "./App.css";
import Hausratsversicherung from "./components/Hausratsversicherung";
import Gebaeudeversicherung from "./components/Gebaeudeversicherung";
import { Col, Layout, Row } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import GlarnerSachFooter from "./components/glarnerSachFooter";
import SubmissionSuccessful from "./components/SubmissionSuccessful";
import InsuranceSelect from "./components/InsuranceSelect";



ReactGA.initialize('G-4Q7XCTQ1X7');


function App() {
    return (
        <Layout style={{minHeight: "100vh"}}>
            {/* <Content> */}
            
            <Routes>
                <Route path="/hausrat7S" element={<Hausratsversicherung />} />
                <Route path="/gebaeude3D" element={<Gebaeudeversicherung />} />
                <Route path="/success" element={<SubmissionSuccessful />} />
                <Route path="/select" element={<InsuranceSelect />} />
            </Routes>
            
            <GlarnerSachFooter />
        </Layout>
    );
}

export default App;
