import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"

const calculateWasser3D = (vollwert: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.wasser
    let praemie = 0

    praemie = ValueInInterval(vollwert, current_params.tarife, current_params.tarifstufen)

    let rabatt = 0

    if (praemie < 0) {
        praemie = 0
    }

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateWasser3D