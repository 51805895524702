import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";

const calculateErdbeben3D = (vollwert: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.erdbeben
    let tarif = current_params.tarif

    let praemie = roundTo5(vollwert * tarif)

    if (praemie < 0) {
        praemie = 0
    }

    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateErdbeben3D