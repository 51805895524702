import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"

const calculateGlasbruch3D = (erstrisiko: any, vollwert: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.glasbruch
    let praemie = 0

    console.log("erstrisiko", erstrisiko)

    switch (erstrisiko) {
        case 5000:
            praemie = ValueInInterval(vollwert, current_params.tarife_5k, current_params.tarifstufen)
            break;
        case 10000:
            praemie = ValueInInterval(vollwert, current_params.tarife_10k, current_params.tarifstufen)
            break;
        default:
            praemie = ValueInInterval(vollwert, current_params.tarife_pauschal, current_params.tarifstufen)
            break;
    }

    if (praemie < 0) {
        praemie = 0
    }

    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateGlasbruch3D