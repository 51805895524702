import params from "../data/parameters.json"

import { HaushaltsType7S } from "../models/HaushaltsType";
import computeTotalHausrat from "./hausrat/HausratsversicherungCalculator";
import calculateTotalPrivathaftpflicht from "./privathaft/PrivathaftpflichtCalculator";
import calculateRechtsschutz from "./rechtsschutz/RechtsschutzCalculator";
import computeTotalHauskasko from "./haushaltkasko/HausratskaskoCalculator";
import { ContractRates7SType } from "../models/ContractRates7SType";

import roundTo5 from "./utils/round_to5";


export const computeTotal = (obj: HaushaltsType7S) => {

    let hausrat_praemie = 0
    let haushaltskasko_praemie = 0
    let privathaftpflicht_praemie = 0
    let rechtsschutz_praemie = 0
    let total_praemien = 0

    let total_rabatt = 0

    if (obj.Hausrat.Enable) {
        hausrat_praemie = computeTotalHausrat(obj.Hausrat).totalPraemie
        total_rabatt += computeTotalHausrat(obj.Hausrat).totalRabatt
        if (hausrat_praemie > 0) {
            haushaltskasko_praemie = computeTotalHauskasko(obj.Haushaltskasko).totalPraemie
            total_rabatt += computeTotalHauskasko(obj.Haushaltskasko).totalRabatt
        }
    }

    if (obj.Privathaftpflicht.Enable) {
        privathaftpflicht_praemie = calculateTotalPrivathaftpflicht(obj.Privathaftpflicht).totalPraemie
    }

    if (obj.Rechtsschutz.Enable) {
        rechtsschutz_praemie = calculateRechtsschutz(obj.Rechtsschutz).totalPraemie
    }

    if (obj.Alter > params.general_params.alter_jugendrabatt) {
        total_rabatt = 0
    }

    total_praemien =
        hausrat_praemie +
        haushaltskasko_praemie +
        privathaftpflicht_praemie +
        rechtsschutz_praemie

    total_rabatt += params.general_params.prozent_rabatt_7S * (total_praemien - total_rabatt) + params.general_params.pauschal_rabatt_7S

    let total_ohne_stempel = roundTo5(total_praemien - total_rabatt)
    let eidg_stempel = roundTo5(total_ohne_stempel * params.general_params.eidg_stempelabgabe)
    let total_mit_stempel = roundTo5(total_ohne_stempel + eidg_stempel)

    return {
        Hausrat: hausrat_praemie,
        HaushaltsKasko: haushaltskasko_praemie,
        Privathaft: privathaftpflicht_praemie,
        Rechtsschutz: rechtsschutz_praemie,
        TotalPraemien: total_praemien,
        TotalRabatt: total_rabatt,
        TotalOhneStempel: total_ohne_stempel,
        TotalOhneStempelMonat: roundTo5(total_ohne_stempel / 12),
        Stempel: eidg_stempel,
        TotalMitStempel: total_mit_stempel
    } as ContractRates7SType
}

export default computeTotal