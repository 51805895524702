import params from "../../data/parameters.json";
import ValueInInterval from "../ValueInInterval"

const calculateDiebstahl3D = (vollwert: number) => {

    vollwert /= 1000

    let current_params = params.gebaeude.diebstahl
    let praemie = 0

    praemie = ValueInInterval(vollwert, current_params.tarife, current_params.tarifstufen) * vollwert

    if (praemie < 0) {
        praemie = 0
    }

    let rabatt = 0

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateDiebstahl3D