import { Col, Row, Space, Button, Grid } from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from "react-router-dom";





const GlarnerSachHeader = (props: any) => {
    const navigate = useNavigate()

    const { useBreakpoint } = Grid
    const screens = useBreakpoint()
    const isMobile = screens.sm

    const onClickGS = (checked: any) => {
        navigate('/select')
    };

    return <Row style={{ justifyContent: 'center' }}>
        <Col sm={{ span: 22 }} style={{ maxWidth: '980px' }}>
            <div>
                <Row justify="start" className="header-row">
                    <Col span={8}>
                        <Button type="link" className="logo-button" onClick={onClickGS} icon={<img className="logo" style={{ paddingBottom: 15 }} src="https://glarnersach.ch/fileadmin/templates/2022.glarnersach.ch/assets/logos/logo-glarnersach.svg" />} />

                    </Col>
                    <Col span={8}>
                        {(!isMobile) &&
                            <div className="title-header">
                                <h3 className="title-header-text">{props.title1} <br/>
                                {props.title2}</h3>
                            </div>
                        }
                    </Col>
                    <Col span={8}>
                        <Row style={{ justifyContent: 'right', paddingTop: '10px' }}>
                            <Button className="social-media-link" type="link" href="mailto:info@glarnersach.ch" style={{}} icon={<img className="symbol-header" width="35" src={require('../mail.svg').default} />} />
                            <div className="symbol-header-space" style={{}}>

                            </div>
                            <Button className="social-media-link" type="link" href="tel:+41556456161" style={{}} icon={<img className="symbol-header" width="35" src={require('../phone.svg').default} />} />
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    </Row>


}


export default GlarnerSachHeader;