
import HauskaskoType from "../../models/Haushaltkasko";
import { PersonenArt } from "../../models/Enums";

import calculateMedizinischeHilfsmittel from "./MedizinischeHilfsmittelCalculator";
import calculateReisegepaeck from "./ReisegepaeckCalculator";
import calculateSportgeraete from "./SportgeraeteCalculator";
import calculateHaushaltsgeraeteUnterhaltungselektronik from "./HaushaltsgeraeteUnterhalungselektronikCalculator";
import calculateWertsachen from "./WertsachenCalculator";

export const computeTotalHauskasko = (obj: HauskaskoType) => {

    let medizin_praemie = 0
    let reise_praemie = 0
    let sport_praemie = 0
    let haushalt_praemie = 0
    let wert_praemie = 0

    let total_praemie = 0
    let total_rabatt = 0

    if (obj.Enable && obj.PersonenArt != null){

        let haushalt_cond = calculateHaushaltsgeraeteUnterhaltungselektronik(obj.HaushaltsgeraeteErstrisiko, obj.PersonenArt)
        let medizin_cond = calculateMedizinischeHilfsmittel(obj.MedizinischeHilfsmittelErstrisiko, obj.PersonenArt)
        let sport_cond = calculateSportgeraete(obj.SportgeraeteErstrisiko, obj.PersonenArt)
        let wert_cond = calculateWertsachen(obj.WertsachenErstrisiko, obj.PersonenArt)
        let reise_cond = calculateReisegepaeck(obj.ReisegepaeckErstrisiko, obj.PersonenArt)

        if (obj.Haushaltsgeraete) {
            haushalt_praemie = haushalt_cond.praemie
            total_rabatt += haushalt_cond.rabatt
        }
    
        if (obj.MedizinischeHilfsmittel) {
            medizin_praemie = medizin_cond.praemie
            total_rabatt += medizin_cond.rabatt
        }
    
        if (obj.Sportgeraete) {
            sport_praemie = sport_cond.praemie
            total_rabatt += sport_cond.rabatt
        }
    
        if (obj.Wertsachen) {
            wert_praemie = wert_cond.praemie
            total_rabatt += wert_cond.rabatt
        }
    
        if (obj.Reisegepaeck) {
            reise_praemie = reise_cond.praemie
            total_rabatt += reise_cond.rabatt
        }
    
        total_praemie =
            medizin_praemie +
            haushalt_praemie +
            sport_praemie +
            wert_praemie +
            reise_praemie
    }
    
    return {
        "totalPraemie": total_praemie,
        "totalRabatt": total_rabatt,
        "medizinPraemie": medizin_praemie,
        "haushaltPramie": haushalt_praemie,
        "sportPraemie": sport_praemie,
        "wertPraemie": wert_praemie,
        "reisePraemie": reise_praemie,
    }
}

export default computeTotalHauskasko