import params from "../../data/parameters.json";
import roundTo5 from "../utils/round_to5";

const calculateMobiliar = () => {

    let params_mobiliar = params.hausratsversicherung.mobiliar
    let praemie = roundTo5(params_mobiliar.pauschalpraemie)
    let rabatt = roundTo5(params.general_params.jugendrabatt_faktor * praemie)

    return { "praemie": praemie, "rabatt": rabatt }
}

export default calculateMobiliar