import initHaushaltsType7S from "./InitHaushalt7S"
import { Contract7SType } from "../models/Contract7SType"
import initContractTaker from "./InitContractTaker"
import params from "../data/parameters.json"

let current_date = new Date();
let start_date = new Date();
start_date.setMonth(current_date.getMonth() + 1)
start_date.setDate(1)
console.log(start_date)

const initContract7S = {
    OffertenNr: null,
    VertragsBeginn: start_date as Date,
    DatumHeute: current_date as Date,

    Vertragsnehmer: initContractTaker,
    Person1: null,
    Person2: null,
    Person3: null,
    Person4: null,
    Person5: null,
    Person6: null,

    HaushaltsV: initHaushaltsType7S
} as Contract7SType

export default initContract7S