import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { resourceLimits } from 'worker_threads';
import PlzData from '../data/plz.json'


// interface UserValue {
//     label: string;
//     value: string;
// }

// export interface DebounceSelectProps<ValueType = any>
//     extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
//     fetchOptions: (search: string) => Promise<ValueType[]>;
//     debounceTimeout?: number;
// }



// function DebounceSelect<
//     ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
//     >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
//     const [fetching, setFetching] = useState(false);
//     const [options, setOptions] = useState<ValueType[]>([]);
//     const fetchRef = useRef(0);

//     const debounceFetcher = useMemo(() => {
//         const loadOptions = (value: string) => {
//             fetchRef.current += 1;
//             const fetchId = fetchRef.current;
//             setOptions([]);
//             setFetching(true);

//             console.log("running for value", value)

//             const filteredMatches = Object.entries(PlzData)
//                 .filter(([zipCode]) => zipCode.startsWith(value))
//                 .reduce((result, [zipCode, city]) => {
//                     let obj = {}
//                     obj["label"] = `${zipCode} ${city}`
//                     obj["value"] = `${zipCode} ${city}`
//                     obj["key"] = `${zipCode} ${city}`
//                     result.append(obj)
//                 }, {});
//             setOptions(filteredMatches)


//             fetch(`https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2&q=${value}&facet=postleitzahl&facet=plz_zz&facet=ortbez18&exclude.plz_typ=80`)
//                 .then(response => response.json())
//                 .then(data => data.records.map((record: any) => ({
//                     label: record.fields.gplz + " " + record.fields.ortbez27,
//                     value: record.fields.gplz + " " + record.fields.ortbez27,
//                     key: record.fields.gplz + " " + record.fields.ortbez27
//                 })))
//                 .then((res: any) => {
//                     console.log("res:", res)
//                     if (fetchId !== fetchRef.current) {
//                         setOptions(res)
//                     } else
//                         setOptions(res)
//                 })
//         };

//         return debounce(loadOptions, debounceTimeout);
//     }, [debounceTimeout])

//     return (
//         <Select
//             labelInValue
//             filterOption={false}
//             onSearch={debounceFetcher}
//             notFoundContent={fetching ? <Spin size="small" /> : null}
//             {...props}
//             options={options}
//         />
//     );
// }


// export default DebounceSelect

type MyJsonObject = {
    [key: string]: string;
  };
  
  type MyArrayElement = {
    label: string;
    value: string;
  };

  const plzDataProc: MyArrayElement[] = Object.entries(PlzData).map(([value, label]) => ({
    value: value,
    label: `${value} ${label}`,
  }));

// const plzDataProc: Array<Record<string, string>> = []
// for (const key: string in plzDataProc) {
//     plzDataProc.push({
//         "label": key,
//         "value": `${key} ${PlzData[key as string]}`
//     })
// }



const MySelectComponent = (props: any) => {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(props.value);
    const [filteredOptions, setFilteredOptions] = useState(plzDataProc);
  
    const handleSearch = (value: string) => {
        let res = []
        for (const key in plzDataProc) {
            const plz = plzDataProc[key].value
            if (plz.startsWith(value)) {
                res.push({"value": plz, "label": `${plzDataProc[key].label}`})
            }
        }
        setFilteredOptions(res)
    };
  
    const handleChange = (value: string) => {
      setSelectedValue(value);
    };
  
    return (
      <Select
        showSearch
        value={selectedValue}
        style={{ width: 200 }}
        placeholder="Select an option"
        optionFilterProp="children"
        onSearch={handleSearch}
        filterOption={false}
        {...props}
      >
        {filteredOptions.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    );
  };
  
  export default MySelectComponent;
  