import params from "../../data/parameters.json";
import RechtsschutzType from "../../models/Rechtsschutz";
import { PersonenArt } from "../../models/Enums";


const calculateRechtsschutz = (obj: RechtsschutzType) => {

    let current_params = params.rechtsschutz
    let praemie = 0

    if (obj.Enable && obj.PersonenArt !== null) {
        if (obj.PersonenArt === PersonenArt.Mehrperson) {
            praemie = current_params.mehrperson
        }
        else {
            praemie = current_params.einzelperson
        }
    }

    return { "totalPraemie": praemie }
}

export default calculateRechtsschutz