import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Button, Table, Collapse, DatePicker, Checkbox, Typography, Switch, Space, Card, Slider, Select, Divider, Radio, message, Row, Col, Grid, Layout, Tooltip, Popover } from "antd";

import { computeTotal3D } from '../calculator/3DGebaeudeCalculator'
import { useForm } from "antd/lib/form/Form";
import { BewohnenArt } from "../models/Enums";
import params from "../data/parameters.json"
import { Content, Header } from "antd/lib/layout/layout";
import transform_amount from "../data/transform_amount_from_params";
import { InfoCircleOutlined } from "@ant-design/icons";
import DebounceSelect from "./PlzField"

import initHausrat from "../inital_values/InitHausrat";
import initHaushaltkasko from "../inital_values/InitHaushaltskasko";
import { Contract3DType } from "../models/Contract3DType";
import initContract3D from "../inital_values/InitContract3D";
import calculateSumme from "../calculator/hausrat/SummenerhebungCalculator";
import axios from "axios";
import { ContractRates3DType } from "../models/ContractRates3DType";
import { checkMarktgebiet } from "../data/CheckMarktgebiet";

import type { ColumnsType } from 'antd/es/table';
import { GebaeudeType3D } from "../models/GebaeudeType3D";
import initialGebaeude3D from "../inital_values/InitGebaeude3D";
import GlarnerSachHeader from "./glarnerSachHeader";
import ReactGA from "react-ga4";



const SubmissionSuccessful = () => {

    const { useBreakpoint } = Grid
    const screens = useBreakpoint()
    const isMobile = screens.xs

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/success" });
    })


    return (
        <Layout>

            <Layout>
                <Header style={{ position: 'fixed', zIndex: 1000, width: '100%', padding: '0px 0px 0px 0px' }} className="header-background-select">
                    <GlarnerSachHeader headerText="" />
                </Header>
                <Content style={{ marginTop: 150 }}>
                    <Row justify="center">
                        <Col xs={18} sm={16} md={14}  style={{ maxWidth: '980px' }}>
                            <div className="success-message">
                                <h4>Guten Tag <br /><br />

                                    Wir danken Ihnen für Ihre Offertanfrage. Diese erhalten Sie in Kürze per E-Mail.<br />

                                    Bei Fragen stehen Ihnen unsere Versicherungsberater/-innen gerne zur Verfügung. <br /><br />

                                    <b>Ihre glarnerSach</b>
                                </h4>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout >
    );
};

export default SubmissionSuccessful;
