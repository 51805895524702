import { EinrichtungsStandard, GebaeudeTyp } from "../../models/Enums"
import params from "../../data/parameters.json";
import { SummenerhebungType } from "../../models/SummenerhebungType";


const calculateSumme = (summe_params: SummenerhebungType) => {

    let value = 0

    if (summe_params.EinrichtungsStandard != null &&
        summe_params.NumKinder != null &&
        summe_params.Personen != null &&
        summe_params.Wohnobjekt != null &&
        summe_params.Zimmer != null) {

        let params_summenerhebung = params["summenerhebung"]

        let key_personen = (summe_params.Personen >= 7) ? "7" : summe_params.Personen.toString()
        let key_zimmer = (summe_params.Zimmer >= 7) ? "7 plus" : summe_params.Zimmer.toString()


        key_personen = key_personen + " person"
        key_zimmer = key_zimmer + " room"

        let rates_zimmer = params_summenerhebung.zimmer_personen[key_zimmer as keyof typeof params_summenerhebung.zimmer_personen]
        let summe = rates_zimmer[key_personen as keyof typeof rates_zimmer]

        // Abzug Kinder
        summe -= summe_params.NumKinder * params_summenerhebung.abzug_pro_kind

        // Zuschlag Haus
        if (summe_params.Wohnobjekt === GebaeudeTyp.Haus) {
            summe += params_summenerhebung.wohnobject.haus
        }
        else {
            summe += params_summenerhebung.wohnobject.wohnung
        }

        // Faktor Einrichtung
        switch (summe_params.EinrichtungsStandard) {
            case EinrichtungsStandard.Einfach:
                summe *= params_summenerhebung.einrichtungsstandard_faktor.einfach
                break
            case EinrichtungsStandard.Mittel:
                summe *= params_summenerhebung.einrichtungsstandard_faktor.mittel
                break
            case EinrichtungsStandard.Gehoben:
                summe *= params_summenerhebung.einrichtungsstandard_faktor.gehoben
                break
        }

        summe = Math.round(summe)

        summe *= 1000

        value = summe
    }

    return value
}

export default calculateSumme