import { Col, Row, Layout, Button, Space, Grid } from "antd"
import { Content, Header, Footer } from "antd/lib/layout/layout";
import GlarnerSachFooter from "./glarnerSachFooter";
import GlarnerSachHeader from "./glarnerSachHeader";
import { useNavigate } from "react-router-dom";

const InsuranceSelect = (props: any) => {

    const navigate = useNavigate()
    const { useBreakpoint } = Grid
    const screens = useBreakpoint()
    const isMobile = screens.xs

    const onClick7S = (checked: any) => {
        navigate('/hausrat7s')
    };

    const onClick3D = (checked: any) => {
        navigate('/gebaeude3d')
    };

    return (
        <Layout style={{}}>
            <Header style={{ position: 'fixed', zIndex: 1000, width: '100%', padding: '0px 0px 0px 0px' }} className="header-background-select">
                <GlarnerSachHeader headerText="" />
            </Header>

            <Content style={{ marginTop: 110 }}>
                <Row justify="center">
                    <Col sm={{ span: 22 }} md={{ span: 18 }} lg={{ span: 14 }} style={{ maxWidth: '980px' }}>
                        <Row justify="center" align="middle">
                            <Space direction="horizontal" className="titlebox" style={{ fontSize: '30px', justifyContent: 'center', color: 'rgb(0,41,79)' }}>
                                <h1>Welche Versicherung möchten Sie abschliessen?</h1>
                            </Space>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col sm={12}>
                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'right', paddingRight: '10px' }}>
                                    <Button type="primary" className="button7s" onClick={onClick7S} style={{ fontSize: '16px', filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))' }}>
                                        <h2 style={{ color: 'rgb(255,255,255)' }}>Haushaltversicherung <br /> SIEBENSACHEN</h2>
                                    </Button>
                                </Space>
                            </Col>
                            <Col sm={12}>
                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'left', paddingLeft: '10px' }}>
                                    <Button type="primary" className="button3d" onClick={onClick3D} style={{ fontSize: '16px', filter: 'drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.25))' }}>
                                        <h2 style={{ color: 'rgb(255,255,255)' }}>Gebäudeversicherung <br />DREIDIMENSIONAL</h2>
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
            {/* <Footer style={{width: '100%', padding: '0px 0px 0px 0px' }} className="black-text-2">
                <GlarnerSachFooter></GlarnerSachFooter>
            </Footer> */}
        </Layout>
    )
}


export default InsuranceSelect;